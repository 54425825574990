import React from "react";
import { StaticMap } from "react-map-gl";
import mapboxgl from "mapbox-gl";
import PropTypes from "prop-types";
const ACCESS_TOKEN = process.env.REACT_APP_MAP_BOX_ACCESS_TOKEN;
const STYLE_URL = process.env.REACT_APP_MAP_BOX_STYLE_URL;

mapboxgl.workerClass =
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
export const Map = (props) => {
  return (
    <StaticMap
      latitude={props.latitude}
      longitude={props.longitude}
      zoom={props.zoom}
      height={props.height}
      width={props.width}
      mapStyle={STYLE_URL}
      mapboxApiAccessToken={ACCESS_TOKEN}
      attributionControl={false}
    >
      {props.children}
    </StaticMap>
  );
};

Map.propTypes = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  zoom: PropTypes.number,
  children: PropTypes.node
};
