// import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const style = {
  control: (base) => ({
    ...base,
    boxShadow: "none",
    width: "215px",
    height: "48px",
    borderRadius: "3px",
    border: "none",
    backgroundColor: "#FAFAFB",
    fontSize: "14px",
    fontFamily: "Open Sans",
    color: "#959393",
  }),
  dropdownIndicator: (css) => ({
    ...css,
    color: "#C4C4C4",
  }),
  placeholder: (css) => ({
    ...css,
    color: "#959393",
  }),
  indicatorSeparator: (css) => ({
    ...css,
    display: "none",
  }),
};

export const Dropdown = ({
  placeholder,
  items,
  isDisabled,
  onChange,
  value,
}) => {

  return (
    <div>
      <Select
        id="drop-down"
        placeholder={placeholder}
        onChange={onChange}
        options={items}
        styles={style}
        autoFocus={true}
        isDisabled={isDisabled}
        value={value}
      />
    </div>
  );
};

Dropdown.propTypes = {
  placeholder: PropTypes.string,
  items: PropTypes.array,
  selected: PropTypes.object,
  onChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  value: PropTypes.string,
};
