import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const StyledTitle = styled.h1`
  color: #959393;
  font-family: "Roboto", sans-serif;
  margin: 0;
  font-size: 30px;
`;

export const Title = ({ title }) => {
  return <StyledTitle>{title}</StyledTitle>;
};

Title.propTypes = {
  title: PropTypes.string,
};
