import React, { Fragment } from "react";
import Background from "../static/login.png";
import { HeaderLogo } from "../components";
import styled from "styled-components";

const Content = styled.div`
  background-image: url(${Background});
  background-size: cover;
  padding-top: 10%;
  padding-left: 15%;
  display: flex;
  justify-content: flex-start;
`;

const Confirmation = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  width: 30%;
  text-align: center;
  justify-items: center;
  margin: 10% 0 0 0;
`;

export const EmailConfirmationError = () => {
  document.title = "Skewl | Email Confirmation Error";

  return (
    <Fragment>
      <HeaderLogo />
      <Content />
      <Confirmation>
        <div>Hello,</div>
        Error occured while confirming your registration. Please try again
      </Confirmation>
    </Fragment>
  );
};
