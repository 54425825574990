import styled from "styled-components";
import React, { useEffect, useState, useRef } from "react";
import { CircularProgressBar, PrimaryButton,BackDrop,Loader } from "../../components";
import { ReactComponent as Human } from "../../static/human.svg";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { Calendar } from "react-modern-calendar-datepicker";
import moment from "moment-business-days";
import { DonutChart } from "./DonutChart";
import { TreeMapChart } from "./TreeMapChart";
import { getTripAnalyticalData } from "../../services";
import { Constants } from "../../utils/constants";
import { useHistory } from "react-router-dom";

import { StackedBarChart } from "./StackedBarChart";

const DashboardContainer = styled.div`
  margin: 6% 0 0 0;
  width: 80%;
  height: 100vh;
  display: flex;
  background: #ffffff;
  flex-direction: row;
`;
const RowContainer = styled.div`
  width: 80%;
  height: ${(props) => props.height};
  margin: ${(props) => props.margin};
  display: flex;
  position: absolute;
  justify-content: space-evenly;
`;
const HumanContainer = styled.div`
  width: 25%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  background: rgba(216, 216, 216, 0);
`;
const HumanOverlay = styled.div`
  width: 26%;
  height: 75%;
  background: #f5f5f5;
  border-radius: 6px;
  position: absolute;
  z-index: 0;
`;
const ApprovalContainer = styled.div`
  width: 35%;
  height: 100%;
  display: flex;
  background: #0caeff;
  flex-direction: column;
  align-items: center;
  border-radius: 6px;
  justify-content: inherit;
`;
const CalendarContainer = styled.div`
  width: 26%;
  max-height: 100%;
  display: flex;
  .custom-calendar {
    --cl-color-disabled: #959393 !important;
    --cl-color-error: #dfdfdf !important;
    --cl-color-primary: #006eff !important;
    --cl-color-primary-light: #ebf4ff !important;
    background: #ffffff !important;
    border-radius: 12px !important;
    color: #3f3b3b !important;
    padding: 0 0 0 0;
    width: 100% !important;
    min-height: 100% !important;
    font-family: Open Sans !important;
  }
  .Calendar__day.-ltr {
    font-size: 14px !important;
    min-height: calc(100% / 8) !important;
  }
  .Calendar__weekDays {
    color: #3f3b3b !important;
    font-size: 14px !important;
  }
  .Calendar__footer {
    margin: -36% 0 0 0;
    position: relative;
    z-index: 1;
  }
`;
const Approval = styled.div`
  width: 80%;
  height: 33%;
  background: #ffffff;
  display: flex;
  border-radius: 6px;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width};
  justify-content: ${(props) => props.alignment};
`;

const ApprovalSubContainerText = styled.span`
  font-family: Open Sans;
  margin:${(props) => props.margin};
  font-size: ${(props) => props.size};
  color: ${(props) => props.color};
`;

const StatisticsContainer = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
  border-radius: 16.32px;
  background-color: #ffffff;
`;
const ChartContainer = styled.div`
  width: 100%;
  height: 90%;
`;

export const DashBoard = () =>
{
  let history = useHistory();
  const donutChartRef = useRef();
  const stackedBarChartRef = useRef();
  const treemapChartRef = useRef();
  const [analyticalData, setAnalyticalData] = useState();
  const [rawData, setRawData] = useState();
  const [filter, setFilter] = useState({ type: [], value: [] });
  const [overallUsage, setOverallUsage] = useState(0);
  const [pendingVerfication, setPendingVerification] = useState(0);

  const [minimumDate, setMinimumDate] = useState();
  const [maximumDate, setMaximumDate] = useState();
  const [donutChartMeasurements, setDonutChartMeasurements] = useState({
    width: 0,
    height: 0
  });
  const [stackedBarChartMeasurements, setStackedbarChartMeasurements] =
    useState({
      width: 0,
      height: 0
    });
  const [treeMapChartMeasurements, setTreeMapChartMeasurements] = useState({
    width: 0,
    height: 0
  });
  const [selectedDayRange, setSelectedDayRange] = useState({
    from: null,
    to: null
  });
  const[showLoader,setShowLoader] = useState(false);
  useEffect(() =>
  {
    async function getTripData ()
    {
      setShowLoader(true);
      const analytical_data = await getTripAnalyticalData();
      if (analytical_data.error === Constants.http_status_codes.UNAUTHROISED)
      {
        setShowLoader(false);
        history.push("/");
      } else {
        let today = new Date();
    setAnalyticalData(analytical_data.data);
    setRawData(analytical_data.data);
    setOverallUsage(analytical_data.overall_usage);
    setPendingVerification(analytical_data.pending_verification);
    let startDate = moment(today, "YYY-MM-DD").businessSubtract(30)._d;
    let maximumDate = {
      year: today.getFullYear(),
      month: today.getMonth() + 1,
      day: today.getDate()
    };
    let minimumDate = {
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
      day: startDate.getDate()
    };
    setMinimumDate(minimumDate);
    setMaximumDate(maximumDate);
    setSelectedDayRange({
      from: minimumDate,
      to: maximumDate
    });
    setStackedbarChartMeasurements({
      width: stackedBarChartRef.current.offsetWidth,
      height: stackedBarChartRef.current.offsetHeight
    });
    setDonutChartMeasurements({
      width: donutChartRef.current.offsetWidth,
      height: donutChartRef.current.offsetHeight
    });
    setTreeMapChartMeasurements({
      width: treemapChartRef.current.offsetWidth,
      height: treemapChartRef.current.offsetHeight
    });
      }
      setShowLoader(false);
    }
    getTripData();
  }, []);
  const getDisabledDays = () => {
    let disableDays = [];
    if (minimumDate && maximumDate) {
      let minimumDatePointer = new Date();
      minimumDatePointer.setFullYear(minimumDate.year);
      minimumDatePointer.setMonth(minimumDate.month - 1);
      minimumDatePointer.setDate(minimumDate.day);
      minimumDatePointer.setHours(0);
      minimumDatePointer.setMinutes(0);
      minimumDatePointer.setSeconds(0);
      minimumDatePointer.setMilliseconds(0);
      let maximumDatePointer = new Date();
      maximumDatePointer.setFullYear(maximumDate.year);
      maximumDatePointer.setMonth(maximumDate.month - 1);
      maximumDatePointer.setDate(maximumDate.day);
      maximumDatePointer.setHours(23);
      maximumDatePointer.setMinutes(59);
      maximumDatePointer.setSeconds(59);
      maximumDatePointer.setMilliseconds(0);
      minimumDatePointer.setDate(
        minimumDatePointer.getDate() + (6 - minimumDatePointer.getDay())
      );

      while (minimumDatePointer < maximumDatePointer) {
        disableDays.push(
          {
            year: minimumDatePointer.getFullYear(),
            month: minimumDatePointer.getMonth() + 1,
            day: minimumDatePointer.getDate()
          },
          {
            year: minimumDatePointer.getFullYear(),
            month: minimumDatePointer.getMonth() + 1,
            day: minimumDatePointer.getDate() + 1
          }
        );
        minimumDatePointer.setDate(minimumDatePointer.getDate() + 7);
      }
    }
    return disableDays;
  };

  const onDateChange = (value) => {
    setSelectedDayRange(value);
  };

  const updateDateFilter = () => {
    let fromDate;
    let toDate;
    fromDate = new Date(
      selectedDayRange.from.year +
        "/" +
        selectedDayRange.from.month +
        "/" +
        selectedDayRange.from.day
    );
    if (selectedDayRange.to) {
      toDate = new Date(
        selectedDayRange.to.year +
          "/" +
          selectedDayRange.to.month +
          "/" +
          selectedDayRange.to.day
      );
    }

    let filterData = rawData.filter((data) => {
      let date = new Date(data.date);
      if (fromDate && toDate) {
        return (
          date.getTime() >= fromDate.getTime() &&
          date.getTime() <= toDate.getTime()
        );
      } else if (fromDate) {
        return date.getTime() === fromDate.getTime();
      }
    });
    if (filterData) {
      setAnalyticalData(filterData);
      setFilter({ type: [], value: [] });
    }
  };
  const reset = () => {
    setFilter({ type: [], value: [] });
  };
  return (
    <DashboardContainer id="dashboard-container">
      <BackDrop showBackdrop={showLoader} setShowBackdrop={setShowLoader}>
        <Loader showLoader={showLoader}/>
      </BackDrop>
      <RowContainer id="summary-container" height={"35%"}>
        <HumanContainer id="human-container">
          <HumanOverlay />
          <Human
            id="human-data"
            height={"100%"}
            width={"100%"}
            style={{ zIndex: "9" }}
          />
        </HumanContainer>
        <ApprovalContainer id="approval-container">
          <Approval>
            <SubContainer width={"80%"} alignment={"center"}>
              <ApprovalSubContainerText size={"14px"} color={"#5D6F88"} margin={ "0 0 0 5%"}>
                Skewl
              </ApprovalSubContainerText>
              <ApprovalSubContainerText size={"24px"} color={"#3F3B3B"} margin={ "0 0 0 5%"}>
                Overall Usage
              </ApprovalSubContainerText>
            </SubContainer>
            <SubContainer width={"20%"} alignment={"center"}>
              <CircularProgressBar
                size={50}
                strokeWidth={4}
                percentage={overallUsage}
                color={"#006EFF"}
              />
            </SubContainer>
          </Approval>
          <Approval>
            <SubContainer width={"80%"} alignment={"center"}>
              <ApprovalSubContainerText size={"14px"} color={"#5D6F88"} margin={ "0 0 0 5%"}>
                Profiles
              </ApprovalSubContainerText>
              <ApprovalSubContainerText size={"24px"} color={"#3F3B3B"} margin={ "0 0 0 5%"}>
                Pending Verification
              </ApprovalSubContainerText>
            </SubContainer>
            <SubContainer width={"20%"} alignment={"space-around"}>

              <ApprovalSubContainerText size={"24px"} color={"#006EFF"} margin={ "0 0 0 5%"}>
                {pendingVerfication}
              </ApprovalSubContainerText>
            </SubContainer>
          </Approval>
        </ApprovalContainer>
        <CalendarContainer>
          <Calendar
            value={selectedDayRange}
            shouldHighlightWeekends
            onChange={onDateChange}
            maximumDate={maximumDate}
            minimumDate={minimumDate}
            disabledDays={getDisabledDays()}
            calendarClassName="custom-calendar"
            renderFooter={() => (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "1rem 2rem"
                }}
              >
                <PrimaryButton
                  type="submit"
                  value="Update Statistics"
                  handleClick={updateDateFilter}
                  width={"150px"}
                  height={"24px"}
                  fontSize={"14px"}
                  borderRadius={"6px"}
                />
              </div>
            )}
          />
        </CalendarContainer>
      </RowContainer>
      <RowContainer
        height={"62%"}
        id="statistics-container"
        margin={"22% 0 0 0"}
      >
        <SubContainer width={"50%"} alignment={"center"}>
          <StatisticsContainer id="trip" height={"95%"}>
            <ApprovalSubContainerText size={"24px"} color={"#3F3B3B"} margin={ "0 0 0 5%"}>
              Commute
            </ApprovalSubContainerText>
            <ChartContainer ref={donutChartRef} id="donut-chart-container">
              {analyticalData && (
                <DonutChart
                  data={analyticalData}
                  width={donutChartMeasurements.width}
                  height={donutChartMeasurements.height}
                  setFilter={setFilter}
                  reset={reset}
                />
              )}
            </ChartContainer>
          </StatisticsContainer>
        </SubContainer>
        <SubContainer width={"45%"} alignment={"space-around"}>
          <StatisticsContainer id="co2-emission" height={"48%"}>
            <ApprovalSubContainerText size={"16.32px"} color={"#3F3B3B"} margin={ "0 0 0 5%"}>
              Shared School Run - CO2 Comparison Footprint
            </ApprovalSubContainerText>
            <ChartContainer
              ref={stackedBarChartRef}
              id="stacked-bar-chart-container"
            >
              {stackedBarChartMeasurements.height != 0 && stackedBarChartMeasurements.width != 0 ?(
                <StackedBarChart
                  data={analyticalData}
                  height={stackedBarChartMeasurements.height}
                  width={stackedBarChartMeasurements.width}
                  top={(stackedBarChartMeasurements.height * 5) / 100}
                  right={(stackedBarChartMeasurements.width * 5) / 100}
                  bottom={(stackedBarChartMeasurements.height * 5) / 100}
                  left={(stackedBarChartMeasurements.width * 10) / 100}
                  filter={filter}
                />):("")}
            </ChartContainer>
          </StatisticsContainer>
          <StatisticsContainer id="pickup-sharing" height={"48%"}>
            <ApprovalSubContainerText size={"16.32px"} color={"#3F3B3B"} margin={ "0 0 0 5%"}>
              Shared School Commute Distribution
            </ApprovalSubContainerText>

            <ChartContainer ref={treemapChartRef} id="treemap-chart-container">
              {treeMapChartMeasurements.height != 0 &&
              treeMapChartMeasurements.width != 0 ? (
                <TreeMapChart
                  data={analyticalData}
                  height={treeMapChartMeasurements.height}
                  width={treeMapChartMeasurements.width}
                  top={(treeMapChartMeasurements.height * 5) / 100}
                  right={(treeMapChartMeasurements.width * 5) / 100}
                  bottom={(treeMapChartMeasurements.height * 5) / 100}
                  left={(treeMapChartMeasurements.width * 10) / 100}
                  filter={filter}
                />
              ) : (
                ""
              )}
            </ChartContainer>
          </StatisticsContainer>
        </SubContainer>
      </RowContainer>
    </DashboardContainer>
  );
};
