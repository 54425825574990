import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const StyledLabel = styled.label`
  font-family: Open Sans;
  font-size: 12px;
  color: #3f3b3b;
`;

export const Label = ({ id, message, labelFor }) => {
  return (
    <StyledLabel data-tip data-for={id} htmlFor={labelFor}>
      {message}
    </StyledLabel>
  );
};

Label.propTypes = {
  message: PropTypes.string,
  labelFor: PropTypes.string,
  id: PropTypes.string
};
