import React, { Fragment, useState } from "react";
import styled from "styled-components";
import Background from "../static/background.png";
import { useHistory } from "react-router-dom";
import { Constants } from "../utils/constants";
import { formAddress } from "../utils/helpers";

import {
  PrimaryButton,
  SecondaryButton,
  Title,
  Input,
  HeaderLogo,
  //UploadFile,
  Error,
  Success
} from "../components";
import { SearchUrn } from "../hoc/Registration/SearchUrn";
import SkewlAuth from "../services/skewl-auth";
import { getSchoolDetails, updateSchoolAdminDetails } from "../services";

const Content = styled.div`
  background-image: url(${Background});
  background-repeat: no-repeat;
  height: 1000px;
  padding-top: 90px;
  display: flex;
  justify-content: center;
  background-color: #006eff;
`;

const RegistrationWrapper = styled.div`
  align-items: center;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-around;
  height: 50%;
  width: 935px;
  max-width: 100%;
`;

const RegistrationFields = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const Actions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 40%;
  justify-content: space-between;
`;

export const Registration = () => {
  document.title = "Skewl | Registration";
  let history = useHistory();

  const [schoolUrn, setSchoolUrn] = useState({ value: "", error: "" });
  const [adminFirstName, setAdminFirstName] = useState({
    value: "",
    error: ""
  });
  const [adminLastName, setAdminLastName] = useState({ value: "", error: "" });
  const [adminEmailId, setAdminEmailId] = useState({ value: "", error: "" });
  const [adminDesignation, setAdminDesignation] = useState({
    value: "",
    error: ""
  });
  const [schoolDetails, setSchoolDetails] = useState({
    schoolName: "",
    schoolAddress: ""
  });
  const [formMessages, setFormMessages] = useState({ error: "", success: "" });

  const handleSchoolSearch = async () => {
    const res = await getSchoolDetails(schoolUrn.value);
    if (res["error"] === Constants.http_status_codes.UNAUTHROISED) {
      history.push("/");
    } else {
      setFormMessages({ error: res["error"], success: "" });
      let school_address = formAddress(res["schoolAddress"]);
      setSchoolDetails({
        schoolName: res["schoolName"],
        schoolAddress: school_address
      });
    }
  };

  const handleSubmit = async (event) => {
    if (
      !schoolUrn.error &&
      !adminFirstName.error &&
      !adminLastName.error &&
      !adminEmailId.error &&
      !adminDesignation.error
    ) {
      const userName = await SkewlAuth.getUserName();
      const res = await updateSchoolAdminDetails(
        schoolUrn.value,
        adminFirstName.value,
        adminLastName.value,
        adminEmailId.value,
        adminDesignation.value,
        userName
      );
      if (res["error"] === Constants.http_status_codes.UNAUTHROISED) {
        history.push("/");
      } else {
        if (res["error"]) {
          setFormMessages({ error: res["error"], success: "" });
        } else {
          setFormMessages({
            error: "",
            success: "School Admin details submitted successfully"
          });
        }
      }
    }
    event.preventDefault();
  };
  const handleCancel = () => {
    history.push("/main");
  };

  const handleChangeInAdminFirstName = (event) => {
    if (!event.target.value) {
      setAdminFirstName({
        value: event.target.value,
        error: "This information is mandatory"
      });
    } else {
      setAdminFirstName({ value: event.target.value, error: "" });
    }
  };

  const handleChangeInAdminLastName = (event) => {
    if (!event.target.value) {
      setAdminLastName({
        value: event.target.value,
        error: "This information is mandatory"
      });
    } else {
      setAdminLastName({ value: event.target.value, error: "" });
    }
  };

  const handleChangeInAdminEmailId = (event) => {
    if (!event.target.value) {
      setAdminEmailId({
        value: event.target.value,
        error: "This information is mandatory"
      });
    } else {
      setAdminEmailId({ value: event.target.value, error: "" });
    }
  };

  const handleChangeInAdminDesignation = (event) => {
    if (!event.target.value) {
      setAdminDesignation({
        value: event.target.value,
        error: "This information is mandatory"
      });
    } else {
      setAdminDesignation({ value: event.target.value, error: "" });
    }
  };
  return (
    <Fragment>
      <HeaderLogo />
      <Content>
        <RegistrationWrapper>
          <Title title="Registration" />
          {formMessages.error && <Error message={formMessages.error} />}
          {formMessages.success && <Success message={formMessages.success} />}
          <RegistrationFields>
            <SearchUrn
              value={schoolUrn.value}
              handleChange={setSchoolUrn}
              handleOnClick={handleSchoolSearch}
            />
            <Input
              name="schoolName"
              placeholder="School Name"
              value={schoolDetails.schoolName}
              readonly={true}
            />
            <Input
              name="schoolAddress"
              placeholder="School Address"
              value={schoolDetails.schoolAddress}
              readonly={true}
            />
            <Input
              name="adminFirstName"
              placeholder="First Name"
              value={adminFirstName.value}
              handleChange={handleChangeInAdminFirstName}
              error={adminFirstName.error}
            />
            <Input
              name="adminLastName"
              placeholder="Last Name"
              value={adminLastName.value}
              handleChange={handleChangeInAdminLastName}
              error={adminLastName.error}
            />
            <Input
              name="adminEmailId"
              placeholder="Admin Email id"
              value={adminEmailId.value}
              handleChange={handleChangeInAdminEmailId}
              error={adminEmailId.error}
            />
            <Input
              name="adminDesignation"
              placeholder="Admin Designation"
              value={adminDesignation.value}
              handleChange={handleChangeInAdminDesignation}
              error={adminDesignation.error}
            />
            {/* <UploadFile /> */}
          </RegistrationFields>
          <Actions>
            <PrimaryButton
              type="submit"
              value="Register"
              handleClick={handleSubmit}
              width={"145px"}
              height={"48px"}
              fontSize={"18px"}
              borderRadius={"6px"}
            />
            <SecondaryButton
              type="button"
              value="cancel"
              handleClick={handleCancel}
              width={"145px"}
              height={"48px"}
              fontSize={"18px"}
              borderRadius={"6px"}
            />
          </Actions>
        </RegistrationWrapper>
      </Content>
    </Fragment>
  );
};
