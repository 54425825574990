import axios from "axios";
import SkewlAuth from "./skewl-auth";
import { Constants } from "../utils/constants";

const SKEWL_HOST = process.env.REACT_APP_API_URL;

export const updateSchoolAddress = async (address) => {
  let res;
  try {
    let apiToken = await SkewlAuth.getToken();
    let session_id = sessionStorage.getItem("session_id");

    const config = {
      method: "put",
      url: `${SKEWL_HOST}/v1/school/address/${encodeURIComponent(session_id)}`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
      data: address,
    };
    res = await axios(config);
    if (res.status === Constants.http_status_codes.UPDATE_SUCCESS) {
      if (res.data.result.session_id) {
        sessionStorage.setItem("session_id", res.data.result.session_id);
      }
      return {
        id: res.data.result.settings_id,
        error: "",
      };
    } else if (res.status === Constants.http_status_codes.PRECONDITION_FAILED) {
      return {
        error: Constants.error.UPDATE_SETTINGS_100,
      };
    } else if (res.status === Constants.http_status_codes.UNAUTHROISED) {
      return {
        error: Constants.http_status_codes.http_status_codes.UNAUTHROISED,
      };
    } else {
      throw new Error("Server Error");
    }
  } catch (error) {
    // console.log("Error while saving school details", error, res);
    console.log(error);
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.UPDATE_SETTINGS_100;
    }
    return {
      error: errorCode,
    };
  }
};
