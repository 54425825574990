import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { ReactComponent as Skewllogo } from "../static/skewl-logo.svg";
import { PrimaryButton, SecondaryButton } from ".";

const ModalWrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  border-radius: 10px;
  width: 500px;
  height: 230px;
  margin: auto;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const modal = {
  initial: { scale: 0 },
  visible: { scale: 1 }
};

const ModalActions = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 20px 0 0 0;
`;

const ModalLogoWrapper = styled.div`
  top: 0;
`;
const EmptyFiller = styled.div`
  width: 20px;
`;

export const ConfirmationModal = (props) => {
  return (
    props.showModal && (
      <ModalWrapper variants={modal} intial="initial" animate="visible">
        <ModalLogoWrapper>
          <Skewllogo />
        </ModalLogoWrapper>
        {props.children}
        <ModalActions>
          <SecondaryButton
            type="button"
            value="Cancel"
            handleClick={props.cancel}
            width={"100px"}
            height={"30px"}
            fontSize={"12px"}
            borderRadius={"16px"}
          />
          <EmptyFiller />
          <PrimaryButton
            type="button"
            value="Confirm"
            handleClick={props.confirm}
            width={"100px"}
            height={"30px"}
            fontSize={"12px"}
            borderRadius={"16px"}
          />
        </ModalActions>
      </ModalWrapper>
    )
  );
};

ConfirmationModal.propTypes = {
  children: PropTypes.node,
  cancel: PropTypes.func,
  confirm: PropTypes.func,
  showModal: PropTypes.bool
};
