import axios from "axios";
import SkewlAuth from "./skewl-auth";
import { Constants } from "../utils/constants";
const SKEWL_HOST = process.env.REACT_APP_API_URL;

export const getProfileData = async (year = "", section = "", urn = "") => {
  const schoolDetails = {
    schoolName: "",
    schoolAddress: "",
    error: "",
  };
  var filterOptions;
  if (urn && urn != "") {
    filterOptions = `/${urn}`;
  }
  if (section != "" && year != "") {
    filterOptions = filterOptions + `/${year}/${section}`;
  } else if (year != "") {
    filterOptions = filterOptions + `/${year}`;
  }

  try {
    let session_id = sessionStorage.getItem("session_id");
    let apiToken = await SkewlAuth.getToken();
    const config = {
      method: "get",
      url: `${SKEWL_HOST}/v1/children/view/${encodeURIComponent(session_id)}`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
    };
    if (filterOptions && filterOptions != "") {
      config.url = config.url + filterOptions;
    }
    const res = await axios(config);
    if (res.status === Constants.http_status_codes.SUCCESS) {
      updateSession(res.data.result);

      return res.data;
    } else if (res.status === Constants.http_status_codes.NO_CONTENT) {
      updateSession(res.data.result);
      return { ...schoolDetails, error: "No year and sections are onboarded" };
    } else if (res.status === Constants.http_status_codes.UNAUTHROISED) {
      return { ...schoolDetails, error: "Invalid Session" };
    } else {
      throw new Error("unknown error");
    }
  } catch (error) {
    console.log(
      "Error occurred while getting school details for the urn: ",
      error
    );
    console.log(error);
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.UPDATE_SETTINGS_100;
    }
    return {
      error: errorCode,
    };
  }
};

const updateSession = (result) => {
  if (result && result.session_id) {
    sessionStorage.setItem("session_id", result.session_id);
  }
};
