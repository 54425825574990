import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import PropTypes from "prop-types";
import styled from "styled-components";

const TreeMapChartContainer = styled.div`
  margin: 1% 1% 1% 1%;
  width: 98%;
  height: 95%;
  svg {
    overflow: visible;
  }
`;
const ToolTipText = styled.text`
  fill: #ffffff;
  font-size: 10px;
  font-family: Open Sans;
  text-anchor: middle;
  background: #5d6f88;
`;

export const TreeMapChart = (props) => {
  const svgRef = useRef();
  let totalSharedTrips = 0;
  const [toolTipPosistion, setToolTipPosition] = useState({
    x: 0,
    y: 0,
    opacity: 0,
    label: "",
    percentage: "",
  });

  let partition = (data) => {
    return d3
      .partition()
      .size([props.width, (props.height * 98) / 100])
      .padding(1)(d3.hierarchy(data).sum((d) => d.value))
      .sort(function (a, b) {
        return a.value - b.value;
      });
  };

  const formatData = (data, filter) => {
    let formattedData = { name: "Total Shared Trips" };
    let children = [];
    data.map((d) => {
      d.school_run.map((s) => {
        let transportation;
        if (filter && filter.type[0] === "transportation_mode") {
          if (filter.value[0] === s.transportation_mode) {
            transportation = s;
          } else {
            transportation = { children: [] };
          }
        } else {
          transportation = s;
        }
        transportation.children.map((trip) => {
          let session;
          if (filter && filter.type[1] && filter.type[1] === "session") {
            if (filter.value[1] === trip.session) {
              session = trip;
            } else {
              session = { riders: [] };
            }
          } else {
            session = trip;
          }
          session.riders.map((ride) => {
            let filter_ks = children.filter((ks) => ride.key_stage === ks.name);

            if (filter_ks.length > 0) {
              let identified_ks = filter_ks[0];
              ride.children.map((year) => {
                let filter_year = identified_ks.children.filter(
                  (yr) => yr.name === year.year
                );
                if (filter_year.length > 0) {
                  let identified_year = filter_year[0];
                  year.children.map((section) => {
                    let filter_section = identified_year.children.filter(
                      (sec) => sec.name === section.section
                    );
                    if (filter_section.length > 0) {
                      let identified_section = filter_section[0];
                      identified_section.value =
                        identified_section.value + section.count;
                    } else {
                      identified_year.children.push(getSection(section));
                    }
                  });
                } else {
                  identified_ks.push(getYear(year));
                }
              });
            } else {
              children.push(getKeyStage(ride));
            }
          });
        });
      });
    });
    formattedData["children"] = children;
    return formattedData;
  };

  const getYear = (data) => {
    let new_year = { name: data.year };
    new_year["children"] = [];
    data.children.map((section) => {
      new_year.children.push(getSection(section));
    });
    return new_year;
  };

  const getSection = (data) => {
    return { name: data.section, value: data.count };
  };

  const getKeyStage = (data) => {
    let new_ks = { name: data.key_stage };
    new_ks["children"] = [];
    data.children.map((year) => {
      new_ks.children.push(getYear(year));
    });
    return new_ks;
  };

  useEffect(() => {
    const svg = d3.select(svgRef.current);
    let formattedData = formatData(props.data, props.filter);
    let root = partition(formattedData);
    totalSharedTrips = root.value;
    svg
      .select("#id-tree-map")
      .selectAll("rect")
      .data(root.descendants())
      .join("rect")
      .attr("id", "id-tree-space")
      .style("stroke", "transparent")
      .style("stroke-width", 4)
      .attr("x", function (d) {
        return d.x0;
      })
      .attr("y", function (d) {
        return d.y0;
      })
      .attr("width", function (d) {
        return d.x1 - d.x0;
      })
      .attr("height", 0)
      .attr("fill", function (d) {
        return d.height === 3
          ? "#2C3E40"
          : d.height === 2
          ? "#809FB0"
          : d.height === 1
          ? "#E8BF76"
          : "#F1A438";
      })
      .attr("rx", "3")
      .attr("ry", "3")
      .on("mouseover", function (d, i) {
        d3.selectAll("id-tree-space")
          .transition()
          .duration(200)
          .style("opacity", 0.5);
        d3.select(this)
          .transition()
          .duration(200)
          .style("opacity", 1)
          .style("stroke", "#41e1c0");

        handleMouseOver(
          (i.x1 + i.x0) / 2,
          (i.y1 + i.y0) / 2,
          i.data ? i.data.name : i.name,
          i.depth,
          i.value
        );
      })
      .on("mouseout", function () {
        d3.selectAll("id-tree-space")
          .transition()
          .duration(200)
          .style("opacity", 0.8);
        d3.select(this)
          .transition()
          .duration(200)
          .style("stroke", "transparent");
        handleMouseOut();
      })
      .transition()
      .ease(d3.easeCubic)
      .duration(1000)
      .attr("height", function (d) {
        return d.y1 - d.y0;
      });

    svg
      .select("#id-tree-map")
      .selectAll("text")
      .data(root.descendants())
      .join("text")
      .attr("x", function (d) {
        return (d.x0 + d.x1) / 2;
      })
      .attr("y", function (d) {
        return (d.y0 + d.y1) / 2;
      })
      .text(function (d) {
        return d.value;
      })
      .attr("font-size", "10px")
      .attr("fill", "#FFFFFF")
      .attr("text-anchor", "middle")
      .attr("transform", function (d) {
        let cx = (d.x0 + d.x1) / 2 + 10 / 2;
        let cy = (d.y0 + d.y1) / 2;
        return d.height === 0 || d.height === 1
          ? `rotate(-90,${cx},${cy})`
          : "";
      });
  }, [props.data, props.filter]);

  const handleMouseOver = (
    toolTipXPosition,
    toolTipYPosition,
    label,
    depth,
    value
  ) => {
    let percentile =
      depth != 0
        ? +parseFloat((value / totalSharedTrips) * 100).toFixed(2) + "%"
        : "";
    setToolTipPosition({
      x: toolTipXPosition,
      y: toolTipYPosition,
      opacity: 1,
      label: label,
      percentage: percentile,
    });
    d3.select("#id-tool-tip").moveToFront();
  };

  const handleMouseOut = () => {
    setToolTipPosition({
      x: 0,
      y: 0,
      opacity: 0,
      label: "",
    });
  };
  return (
    <TreeMapChartContainer>
      <svg
        ref={svgRef}
        viewBox={`0 0 ${props.width} 
                              ${props.height}`}>
        <g>
          <g id="id-tree-map" />
          <g id="id-tool-tip">
            <rect
              id="tool-tip"
              x={toolTipPosistion.x - 60}
              y={toolTipPosistion.y + 5}
              opacity={toolTipPosistion.opacity}
              height={20}
              width={120}
              rx={3}
              ry={3}
              fill="#5d6f88"
            />
            <ToolTipText
              x={toolTipPosistion.x}
              y={toolTipPosistion.y + 20}
              opacity={toolTipPosistion.opacity}>
              {toolTipPosistion.label +
                (toolTipPosistion.percentage
                  ? " (" + toolTipPosistion.percentage + ")"
                  : "")}
            </ToolTipText>
          </g>
        </g>
      </svg>
    </TreeMapChartContainer>
  );
};
TreeMapChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  filter: PropTypes.object,
};
