import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "./icon";
import PropTypes from "prop-types";
import "./style.css";

export const Navigation = ({ activeItemId, onSelect, items, setTitle }) => {
  const [activeSubNav, setActiveSubNav] = useState({
    expanded: true,
    selectedId: activeItemId
  });

  const updateSelectedItem = (item, isSubNav) => {
    setTitle(item.headerTitle);
    return isSubNav
      ? `side-navigation-panel-select-inner-option-selected`
      : "side-navigation-panel-select-option-selected";
  };

  // Listen for parent prop changes and update state
  React.useEffect(() => {
    setActiveSubNav((originalSubNav) => ({
      expanded: originalSubNav.expanded,
      selectedId: activeItemId
    }));
  }, [activeItemId]);

  function handleClick(itemId, title) {
    // call the callback if supplied
    onSelect === null || onSelect === void 0
      ? void 0
      : onSelect({ itemId, title });
  }

  function handleSubNavExpand(item) {
    if (activeSubNav.expanded) {
      const currentItemOrSubNavItemIsOpen =
        // either the parent item is expanded already
        item.itemId === activeSubNav.selectedId ||
        // or one of its expandable children is selected
        (item.subNav &&
          item.subNav.some(
            (_subNavItem) => _subNavItem.itemId === activeSubNav.selectedId
          )) ||
        false;
      setActiveSubNav({
        expanded:
          item.subNav && item.subNav.length > 0
            ? !currentItemOrSubNavItemIsOpen
            : false,
        selectedId: item.itemId
      });
    } else {
      setActiveSubNav({
        expanded: !!(item.subNav && item.subNav.length > 0),
        selectedId: item.itemId
      });
    }
  }

  // eslint-disable-next-line react/display-name
  return React.createElement(
    React.Fragment,
    null,
    items.length > 0 &&
      React.createElement(
        "nav",
        {
          role: "navigation",
          "aria-label": "side-navigation",
          className: "side-navigation-panel"
        },
        items.map((item) => {
          const ElemBefore = item.elemBefore;
          const isActiveTab =
            // item is expanded and
            activeSubNav.expanded &&
            // either the current expandable section is selected
            (item.itemId === activeSubNav.selectedId ||
              // or some item in the expandable section of the current item is selected or active
              (item.subNav &&
                item.subNav.some(
                  (_subNavItem) =>
                    _subNavItem.itemId === activeSubNav.selectedId
                )) ||
              false);
          // eslint-disable-next-line react/display-name
          return React.createElement(
            "ul",
            { key: item.itemId, className: "side-navigation-panel-select" },
            React.createElement(
              "li",
              { className: "side-navigation-panel-select-wrap" },
              React.createElement(
                "div",
                {
                  onClick: () => {
                    handleSubNavExpand(item);
                    handleClick(item.itemId, item.headerTitle);
                  },
                  className: `side-navigation-panel-select-option  ${
                    activeSubNav.selectedId === item.itemId
                      ? updateSelectedItem(item, false)
                      : ""
                  }`
                },
                React.createElement(
                  "span",
                  { className: "side-navigation-panel-select-option-wrap" },
                  ElemBefore && React.createElement(ElemBefore, null),
                  React.createElement(
                    "span",
                    { className: "side-navigation-panel-select-option-text" },
                    item.title
                  )
                ),
                item.subNav &&
                  item.subNav.length > 0 &&
                  (isActiveTab
                    ? React.createElement(ChevronUpIcon, null)
                    : React.createElement(ChevronDownIcon, null))
              )
            ),
            item.subNav &&
              item.subNav.length > 0 &&
              isActiveTab &&
              React.createElement(
                "ul",
                { className: "side-navigation-panel-select-inner" },
                item.subNav.map((subNavItem) => {
                  return React.createElement(
                    "li",
                    {
                      key: subNavItem.itemId,
                      className: "side-navigation-panel-select-inner-wrap"
                    },
                    React.createElement(
                      "div",
                      {
                        onClick: () => {
                          setActiveSubNav(
                            Object.assign(Object.assign({}, activeSubNav), {
                              selectedId: subNavItem.itemId
                            })
                          );
                          handleClick(
                            subNavItem.itemId,
                            subNavItem.headerTitle
                          );
                        },
                        className: `side-navigation-panel-select-inner-option  ${
                          activeSubNav.selectedId === subNavItem.itemId
                            ? updateSelectedItem(subNavItem, true)
                            : ""
                        } `
                      },
                      subNavItem.title
                    )
                  );
                })
              )
          );
        })
      )
  );
};

Navigation.propTypes = {
  activeItemId: PropTypes.string,
  onSelect: PropTypes.func,
  items: PropTypes.array,
  setTitle: PropTypes.func
};
