import React from "react";
import { motion } from "framer-motion";

const pathMotion = {
  hidden: {
    opacity: 0,
    pathLength: 0
  },
  visible: {
    opacity: 1,
    pathLength: 1,
    transition: {
      duration: 1
    }
  }
};

export const RelationAddress = () => {
  return (
    <motion.svg
      xmlns="http://www.w3.org/2000/svg"
      width="70px"
      height="90px"
      viewBox="0 0 150 150"
      margintop="20"
    >
      <motion.path
        d="m0 0h150m-75 0v200z"
        stroke="#ceefff"
        strokeWidth="10px"
        variants={pathMotion}
        initial="hidden"
        animate="visible"
      />
    </motion.svg>
  );
};
