import axios from "axios";
import SkewlAuth from "./skewl-auth";
import { Constants } from "../utils/constants";

const SKEWL_HOST = process.env.REACT_APP_API_URL;

export const getSchoolSettings = async () => {
  try {
    let session_id = sessionStorage.getItem("session_id");
    let apiToken = await SkewlAuth.getToken();
    const config = {
      method: "get",
      url: `${SKEWL_HOST}/v1/school/settings/view/${encodeURIComponent(
        session_id
      )}`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
    };

    const res = await axios(config);
    if (res.status === Constants.http_status_codes.SUCCESS) {
      if (res.data.result.session_id) {
        sessionStorage.setItem("session_id", res.data.result.session_id);
      }
      return {
        id: res.data.result.id,
        profile_approval_enabled: res.data.result.profile_approval_enabled,
        otp_based_dismissal_enabled: res.data.result.otp_based_dismissal,
        dismissal_time: res.data.result.dismissal_time,
        evening_schedule_cut_off_time:
          res.data.result.evening_schedule_cut_off_range,
      };
    } else {
      throw new Error("unknown error");
    }
  } catch (error) {
    console.log(error);
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.UPDATE_SETTINGS_100;
    }
    return {
      error: errorCode,
    };
  }
};

export const updateSchoolSettings = async (
  otp_based_dismissal,
  dismissal_time,
  evening_last_schedule_cut_off_time,
  id,
  logo_url,
  logo_background_color
) => {
  const data = {
    id: id,
    otp_based_dismissal: otp_based_dismissal,
    dismissal_time: dismissal_time,
    evening_last_schedule_cut_off_time: evening_last_schedule_cut_off_time,
    logo_url: logo_url,
    logo_background_color: logo_background_color,
  };

  let res;
  try {
    let apiToken = await SkewlAuth.getToken();
    let session_id = sessionStorage.getItem("session_id");

    const config = {
      method: "put",
      url: `${SKEWL_HOST}/v1/school/settings/${encodeURIComponent(session_id)}`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
      data: data,
    };
    res = await axios(config);
    if (res.status === Constants.http_status_codes.UPDATE_SUCCESS) {
      if (res.data.result.session_id) {
        sessionStorage.setItem("session_id", res.data.result.session_id);
      }
      return {
        id: res.data.result.settings_id,
        error: "",
      };
    } else if (res.status === Constants.http_status_codes.PRECONDITION_FAILED) {
      return {
        error: Constants.error.UPDATE_SETTINGS_100,
      };
    } else if (res.status === Constants.http_status_codes.UNAUTHROISED) {
      return {
        error: Constants.http_status_codes.http_status_codes.UNAUTHROISED,
      };
    } else {
      throw new Error("Server Error");
    }
  } catch (error) {
    // console.log("Error while saving school details", error, res);
    console.log(error);
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.UPDATE_SETTINGS_100;
    }
    return {
      error: errorCode,
    };
  }
};
