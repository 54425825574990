import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Constants } from "../../utils/constants";
import { Filters } from "./Filters";
import { getEveningPickups, generateOTP } from "../../services";
import { motion } from "framer-motion";
import { VolunteerDetails } from "./VolunteerDetails";
import { ReactComponent as Print } from "../../static/print.svg";
import { BackDrop, Loader } from "../../components";
import printJS from "print-js";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 5%;
  height: 100%;
`;
const TripContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 75%;
  margin: 12% 2.5% 0 2.5%;
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
  border-radius: 16px;
  position: absolute;
`;
const TripHeaderContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  background: #f5f5f5;
  justify-content: space-evenly;
  align-items: center;
`;
const TripFooterContainer = styled(motion.div)`
  display: flex;
  width: 100%;
  background: #f5f5f5;
  justify-content: flex-end;
  align-items: center;
`;
const HeadingText = styled(motion.div)`
  color: #959393;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  margin: 2% 0 2% 0;
  text-align: center;
  width: ${(p) => p.width};
`;
const FooterText = styled(motion.div)`
  color: #959393;
  border-right: ${(p) => p.borderRight};
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin: 1% 0 1% 0;
  width: 10%;
`;

const TableActionContainer = styled(motion.div)`
  display: flex;
  width: 90%;
  justify-content: flex-end;
  align-items: center;
  margin: ${(p) => p.margin};
`;
const PrintText = styled(motion.div)`
  color: #0caeff;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  padding: 0 0.5% 0 0.5%;
`;
const TripCountText = styled(motion.span)`
  color: #959393;
  border-right: ${(p) => p.borderRight};
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin: 1% 0 1% 0;
  padding-left: 0.5%;
`;
const Button = styled.button`
  color: #959393;
  border-right: ${(p) => p.borderRight};
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  margin: 1% 0 1% 0;
  width: 10%;
  border: 0;
  background: transparent;
  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

const DataActionContainer = styled.div`
  width: 10%;
  display: flex;
  margin: 0 0 0 90%;
`;
const NoPickups = styled.div`
  color: #303133;
  font-family: Open Sans;
  font-size: 12.6px;
  width: 100%;
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
`;

export const TripDetails = () => {
  let history = useHistory();
  const maxRows = 10;
  const [pickups, setPickups] = useState();
  const [currentFilter, setCurrentFilter] = useState();
  const [slicedPickups, setSlicedPickups] = useState([]);
  const [filterDetails, setFilterDetails] = useState();
  const [enableOTPGeneration, setEnableOTPGeneration] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [pagePointer, setPagePointer] = useState(1);
  useEffect(() => {
    async function getInitialData() {
      let today = new Date();
      /** Adding an hour as toISOString() always return UTC which is an hour behind british time */
      today.setHours(today.getHours() + 1);
      let data = await getEveningPickups(today.toISOString().slice(0, 10));
      if (data && data.error === Constants.http_status_codes.UNAUTHROISED) {
        history.push("/");
      } else {
        setPickups(data.trip_data);
        setSlicedPickups(data.trip_data);
        setEnableOTPGeneration(data.enable_otp_generation);
        setFilterDetails(data.key_stages);
        setPagePointer(1);
        setShowLoader(false);
      }
    }
    getInitialData();
  }, []);
  const handlePrint = () => {
    let printableJson = [];
    slicedPickups.map((trip, index) => {
      let trip_data = { S_No: index + 1 };
      let children = "";
      trip_data["Volunteer"] =
        trip.volunteer.first_name + " " + trip.volunteer.last_name;
      trip.children.map((child) => {
        children = children + child.first_name + " " + child.last_name + ",";
      });
      trip_data["Children"] = children.substring(0, children.length - 1);
      trip_data["Pickup Time"] = getPickupClockTime(trip.pickup_time);
      trip_data["OTP"] = trip.otp ? trip.otp : "******";
      printableJson.push(trip_data);
    });
    let printConfiguration = {
      printable: printableJson,
      properties: [
        { field: "S_No", displayName: "S.No", columnSize: "2%" },
        { field: "Volunteer", displayName: "Volunteer", columnSize: "25%" },
        { field: "Children", displayName: "Children", columnSize: "62%" },
        { field: "Pickup Time", displayName: "Pickup Time", columnSize: "10%" },
        { field: "OTP", displayName: "OTP", columnSize: "5%" }
      ],
      type: "json",
      targetStyles: ["td"],
      documentTitle: "Evening Trip Chart",
      gridHeaderStyle:
        "color: #959393;font-family: Open Sans; font-size: 12px; font-weight: 700;border: 1px solid #f5f5f5;background:#f5f5f5;",
      gridStyle:
        "border: 1px solid #f5f5f5;color:#3f3b3b;font-family: Open Sans;font-size: 13px;font-weight: 500;",
      style: `.header { color: #0caeff; font-family: Open Sans; font-size: 14px; font-weight: 700;}`
    };
    if (currentFilter && currentFilter != "") {
      printConfiguration[
        "header"
      ] = `<h3 class="header">Pickup Chart for ${currentFilter}</h3>`;
    }
    printJS(printConfiguration);
  };
  const getPickupClockTime = (data) => {
    let minutes = data % 60;
    let hours = (data - minutes) / 60;
    minutes = minutes === 0 ? "00" : minutes;
    let clockTime =
      hours <= 12
        ? hours + ":" + minutes + " AM"
        : hours === 12
        ? hours + ":" + minutes + " Noon"
        : hours - 12 + ":" + minutes + " PM";
    return clockTime;
  };
  const pickupDataByDate = async (date) => {
    setShowLoader(true);
    let data = await getEveningPickups(date);
    if (data && data.error === Constants.http_status_codes.UNAUTHROISED) {
      history.push("/");
    } else {
      console.log(JSON.stringify(data));
      setPickups(data.trip_data);
      setCurrentFilter(false);
      setSlicedPickups(data.trip_data);
      setFilterDetails(data.key_stages);
      setEnableOTPGeneration(data.enable_otp_generation);
      setPagePointer(1);
      setShowLoader(false);
    }
  };
  const handleNext = (e) => {
    pickups && pagePointer + maxRows <= pickups.length
      ? setPagePointer(pagePointer + maxRows)
      : "";

    e.preventDefault();
  };

  const handlePrevious = (e) => {
    setPagePointer(pagePointer - maxRows);
    e.preventDefault();
  };
  const filterData = (year, section) => {
    setShowLoader(true);
    let filterCriteria;
    let filterData = [];
    if (pickups) {
      pickups.map((pickup) => {
        let pickup_Data = {};
        let children = [];
        pickup.children.map((child) => {
          section && section === child.year_id
            ? children.push(child)
            : year && year === child.education_stage
            ? children.push(child)
            : "";
        });
        if (children.length > 0) {
          if (!filterCriteria) {
            filterCriteria = section
              ? children[0].year + " " + children[0].section
              : children[0].year;
          }
          pickup_Data = Object.assign({}, pickup);
          pickup_Data.children = children;
          filterData.push(pickup_Data);
        }
      });
    }
    if (filterData.length > 0) {
      setCurrentFilter(filterCriteria);
      setSlicedPickups(filterData);
    }
    setShowLoader(false);
  };
  const initiateOTPGeneration = async () => {
    setShowLoader(true);
    let data = await generateOTP();
    if (data && data.error === Constants.http_status_codes.UNAUTHROISED) {
      history.push("/");
    } else {
      setPickups(data.trip_data);
      setSlicedPickups(data.trip_data);
      setFilterDetails(data.key_stages);
      setEnableOTPGeneration(data.enable_otp_generation);
      setPagePointer(1);
      setShowLoader(false);
    }
  };
  return (
    <Container id="trip-main-container">
      (
      <BackDrop showBackdrop={showLoader} setShowBackdrop={setShowLoader}>
        <Loader showLoader={showLoader} />
      </BackDrop>
      <Filters
        id="filters"
        masterData={filterDetails}
        enableOTPGeneration={enableOTPGeneration}
        filteredData={filterData}
        initiateOTPGeneration={initiateOTPGeneration}
        pickupDataByDate={pickupDataByDate}
      />
      {pickups && pickups.length ? (
        <TripContainer id="trip-container">
          <DataActionContainer id="data-action-container">
            <TableActionContainer id="print-container" onClick={handlePrint}>
              <Print width={"15"} height={"15"} />
              <PrintText>Print</PrintText>
            </TableActionContainer>
          </DataActionContainer>
          <TripHeaderContainer id="trip-head-container">
            <HeadingText width={"25%"}>Volunteer</HeadingText>
            <HeadingText width={"64%"}>Children Details</HeadingText>
            <HeadingText width={"10%"}>Pickup Time</HeadingText>
            <HeadingText width={"5%"}>OTP</HeadingText>
          </TripHeaderContainer>

          {slicedPickups &&
            slicedPickups
              .slice(pagePointer - 1, pagePointer - 1 + maxRows)
              .map((pickup, index) => {
                return (
                  <VolunteerDetails
                    key={index}
                    data={pickup.volunteer}
                    pickup_time={getPickupClockTime(pickup.pickup_time)}
                    transportation={pickup.transportation_mode}
                    children_data={pickup.children}
                    run_id={pickup.run_id}
                    otp={pickup.otp}
                  />
                );
              })}

          <TripFooterContainer id="trip-footer-container">
            <FooterText borderRight={"1px solid #dfdfdf"}>
              <TripCountText>
                Total Pickups {pickups && pickups.length}
              </TripCountText>
            </FooterText>
            <FooterText borderRight={"1px solid #dfdfdf"}>
              <TripCountText>
                shows
                {" " +
                  pagePointer +
                  " - " +
                  (pickups &&
                    (pickups.length <= maxRows
                      ? pagePointer
                      : pagePointer + maxRows - 1 > pickups.length
                      ? pickups.length
                      : pagePointer + maxRows - 1))}
              </TripCountText>
            </FooterText>
            {pagePointer <= maxRows ? (
              <Button disabled>{"<< Previous"}</Button>
            ) : (
              <Button onClick={handlePrevious} layout>
                {"<< Previous"}
              </Button>
            )}
            {pickups &&
            (pagePointer + maxRows === pickups.length ||
              pagePointer + maxRows > pickups.length) ? (
              <Button disabled>{"Next >>"}</Button>
            ) : (
              <Button onClick={handleNext} layout>
                {"Next >>"}
              </Button>
            )}
          </TripFooterContainer>
        </TripContainer>
      ) : (
        <NoPickups>
          No pickups found for chosen date. Please try again later
        </NoPickups>
      )}
    </Container>
  );
};
