import React, { Fragment, useState } from "react";
import Background from "../static/login.png";
import Kid from "../static/kids.png";
import { HeaderLogo, Title, PrimaryButton, Footer } from "../components";
import styled from "styled-components";
import { Username } from "../hoc/Login";
import SkewlAuth from "../services/skewl-auth";
import { useHistory } from "react-router-dom";

const Content = styled.div`
  background-image: url(${Background});
  background-size: cover;
  padding-top: 10%;
  padding-left: 15%;
  display: flex;
  justify-content: flex-start;
`;

const WelcomeBackText = styled.h1`
  height: 70px;
  width: 426px;
  color: #0099ff;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 70px;
  margin: 0 0 14px 0;
`;

const LoginWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginSection = styled.div`
  height: 525px;
  width: 445px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 25px;
`;

const StyledImage = styled.div`
  padding-left: 25%;
`;

const FooterDivider = styled.div`
  height: 1px;
  width: 428px;
  background-color: #979797;
  opacity: 0.5;
  margin-top: 83px;
`;

export const ForgottenPassword = () => {
  document.title = "Skewl | Forgot Password";
  let history = useHistory();
  const [username, setUsername] = useState("");
  const handleUsernameLogin = (value) => {
    setUsername(value);
  };

  const handleForgottenPassword = async (event) => {
    try {
      await SkewlAuth.forgotPassword(username);
      history.push("/resetpassword");
    } catch (error) {
      console.log(error);
    }
    event.preventDefault();
  };

  return (
    <Fragment>
      <HeaderLogo />
      <Content>
        <LoginWrapper>
          <WelcomeBackText>Welcome Back!</WelcomeBackText>
          <LoginSection>
            <Title title="Forgot Pasword" />
            <Username value={username} handleOnInput={handleUsernameLogin} />
            <PrimaryButton
              type="submit"
              value="Submit"
              handleClick={handleForgottenPassword}
              width={"305px"}
              height={"48px"}
              fontSize={"18px"}
              borderRadius={"6px"}
            />
            <FooterDivider />
            <Footer />
          </LoginSection>
        </LoginWrapper>
        <StyledImage>
          <img src={Kid} />
        </StyledImage>
      </Content>
    </Fragment>
  );
};
