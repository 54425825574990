import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

export const CircularProgressBar = ({
  size,
  strokeWidth,
  percentage,
  color
}) => {
  const [progress, setProgress] = useState(0);

  const updatePercentage = () => {
    setTimeout(() => {
      setProgress(progress + 0.1);
    }, 30);
  };

  useEffect(() => {
    if (percentage > 0) updatePercentage();
  }, [percentage]);

  useEffect(() =>
  {
    if (progress < percentage) updatePercentage();
  }, [progress]);
  const viewBox = `0 0 ${size} ${size}`;
  const radius = (size - strokeWidth) / 2;
  const circumference = radius * Math.PI * 2;
  const dash = (progress * circumference) / 100;

  return (
    <svg width={size} height={size} viewBox={viewBox}>
      <circle
        fill="none"
        stroke="#F3F3F3"
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        fill="none"
        stroke={color}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        strokeDasharray={[dash, circumference - dash]}
        strokeLinecap="round"
      />
      <text
        fill={color}
        fontSize="14.4px"
        x="20%"
        y="60%"
        font={"Open Sans"}
        fontWeight={"bold"}
      >
        {`${progress.toFixed(1)}%`}
      </text>
    </svg>
  );
};

CircularProgressBar.propTypes = {
  size: PropTypes.number,
  strokeWidth: PropTypes.number,
  percentage: PropTypes.number,
  color: PropTypes.string
};
