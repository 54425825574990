import React, { useEffect } from "react";
import SkewlAuth from "../../services/skewl-auth";
import { Redirect } from "react-router-dom";
export const Signout = () => {
  useEffect(() => {
    async function globalSignout() {
      await SkewlAuth.signout();
    }
    globalSignout();
  }, []);

  return <Redirect to="/" />;
};
