import React from "react";
import { ReactComponent as Lock } from "../../static/lock.svg";
import PropTypes from "prop-types";
import { InputWithIcon } from "../../components";

export const Password = ({ value, handleOnInput, placeholder, error,onBlur,onFocus }) => {
  const handlePasswordWithValidation = (event) => {
    handleOnInput(event.target.value);
  };

  return (
    <InputWithIcon
      name="Password"
      placeholder={placeholder}
      type="password"
      Icon={Lock}
      value={value}
      handleChange={handlePasswordWithValidation}
      error={error}
      onBlur={onBlur}
      onFocus={onFocus}
    />
  );
};

Password.propTypes = {
  value: PropTypes.string,
  handleOnInput: PropTypes.func,
  placeholder: PropTypes.string,
  error: PropTypes.string,
  onBlur:PropTypes.func,
  onFocus:PropTypes.func
};
