import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import { ReactComponent as Child } from "../../static/child.svg";
import { Storage } from "aws-amplify";
import { Constants } from "../../utils/constants";
import { Avatar } from "../../components";

const RDetails = styled(motion.div)`
  display: flex;
  font-family: Open Sans;
`;
const RDetailsContainer = styled(motion.div)`
  display: flex;
  align-items: center;
`;

const RNameContainer = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;
const RText = styled(motion.span)`
  color: #3f3b3b;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

export const RiderDetails = (props) => {
  const [childImage, setChildImage] = useState(false);

  useEffect(() => {
    async function loadImage() {
      if (props.childData && props.childData.photo_url) {
        Storage.configure({});
        let childImage = await Storage.get(props.childData.photo_url, {
          level: "public",
          expires: 60,
          customPrefix: {
            public: Constants.profile_image_folders.children
          }
        });
        setChildImage(childImage);
      } else {
        setChildImage(false);
      }
    }
    loadImage();
  }, [childImage, props.childData.photo_url]);
  return (
    <RDetails id="r-details-container">
      <RDetailsContainer>
        {childImage ? (
          <Avatar key={props.childData.photo_url} size={"s"} img={childImage} />
        ) : (
          <Child width="50px" height="50px" />
        )}

        <RNameContainer>
          <RText>
            {props.childData.first_name + " " + props.childData.last_name}
          </RText>
          <RText>{props.childData.year + " " + props.childData.section}</RText>
        </RNameContainer>
      </RDetailsContainer>
    </RDetails>
  );
};
RiderDetails.propTypes = {
  childData: PropTypes.object
};
