export const Constants = {
  messages: {
    UPDATE_SETTINGS_OTP_SWITCHED_OFF: `Thanks for updating the school settings. After this change, all parents/Legal Guardians do not require to verify their identity when collecting their children.`,
    UPDATE_SETTINGS_OTP_SWITCHED_ON: `Thanks for updating the school settings. After this change, all parents/Legal Guardians will require to verify their identity by showing OTP when collecting their children. School is also agreeing to verify the OTP`,
    UPDATE_SETTINGS_SUCCESS: `The settings have been updated successfuly`,
    UPDATE_SCHOOL_ADDRESS:`The school address will be updated`,
    UPDATE_SCHOOL_DETAILS: `The school details will be updated`,
    UPDATE_ADDRESS_SUCCESS: `The address updated successfuly`,
    UPDATE_SCHOOL_SUCCESS: `The school details have been updated successfuly`,
    RESET_PASSWORD: "Verification code has been sent to your register email address. Please follow the instructions from email",
    RESET_PASSWORD_SUCCESSFUL: "Password reset successful, Please login again",
    PASSWORD_HELP_TEXT:"Password should contain alphabets and minimum of one letter capital",
    CHANGE_PASSWORD_HELP_TEXT: "Should match with New Password"
  },
  error: {
    UPDATE_SETTINGS_100: `Server error while updating the details, please try again`,
    PROFILE_VERIFICATION_UPDATE_100: `Error while approving child `,
    PROFILE_VERIFICATION_UPDATE_101: "Please enter your comments",
    SCHOOL_SEARCH_100: `Server error searching for school urn, please try again`,
    SCHOOL_ADMIN_ONBOARD_100: `Server error while saving the details, please try again`,
    SCHOOL_ADMIN_ONBOARD_101: `There are missing fields, please check before submitting`,
    SCHOOL_ADMIN_ONBOARD_102: `School admin user already created`,
    LOGIN_ERROR : "Please check your credentials. Either email or password is incorrect",
    SESSION_EXPIRED: "Your session is expired, please login again"
  },
  http_status_codes: {
    UNAUTHROISED: 401,
    SUCCESS: 200,
    UPDATE_SUCCESS: 210,
    PRECONDITION_FAILED: 412,
    NO_CONTENT: 204,
    CONFLICTS:409,
    RESOURCE_CREATED:201
  },
  profile_image_folders: {
    profile_owners: "profile_owners/",
    children: "children/",
  },
};
