import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as SearchIcon } from "../static/search-magnifier.svg";
import { Error } from "./Error";

const StyledFloatingLabel = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 48px;
  font-family: Open Sans;
  &:focus-within label  {
    transform: translate(0, 5px) scale(0.75);
    transition: display 0.2s ease-out;
    display: block;
`;

const StyledLabel = styled.label`
  color: #959393;
  display: none;
  font-family: Open Sans;
  font-size: 14px;
  position: absolute;
  padding-left: 17px;
  transform: translate(0, 16px) scale(1);
  transform-origin: top left;
  transition: display 0.2s ease-out;
`;

export const StyledFormInput = styled.input`
  box-sizing: border-box;
  border: 1px solid #eaeaea;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
  color: #959393;
  font-family: Open Sans Light;
  font-size: 14px;
  font-weight: 300;
  flex-basis: 100%;
  flex-grow: 1;
  height: 48px;
  letter-spacing: 0;
  line-height: 24px;
  padding-left: 12px;
`;

const StyledInput = styled(StyledFormInput)`
  max-width: 100%;
  &:focus::placeholder {
    color: transparent;
  }
`;

const StyledSearchInput = styled(StyledFormInput)`
  width: 100%;
  &:focus::placeholder {
    color: transparent;
  }
`;

const SearchInput = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledSearchIcon = styled(SearchIcon)`
  position: absolute;
  right: 8px;
  cursor: pointer;
`;

const Container = styled.div`
  max-width: 45%;
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  margin: 0 2px 14px 0;
  flex-basis: 100%;
  flex-grow: 1;
  position: sticky;
  height: 48px;
`;

export const Input = ({
  name,
  placeholder,
  value,
  handleChange,
  readonly,
  error,
  type,
  onBlur,
  onFocus
}) => {
  return readonly ? (
    <Container>
      <StyledFloatingLabel>
        <StyledInput
          name={name}
          placeholder={placeholder}
          type={type ? type : "text"}
          value={value}
          readOnly
        />
        <StyledLabel>{placeholder}</StyledLabel>
      </StyledFloatingLabel>
      {error && <Error message={error} />}
    </Container>
  ) : (
    <Container>
      <StyledFloatingLabel>
        <StyledInput
          name={name}
          placeholder={placeholder}
          type={type ? type : "text"}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
        <StyledLabel>{placeholder}</StyledLabel>
      </StyledFloatingLabel>
      {error && <Error message={error} />}
    </Container>
  );
};

export const InputWithSearch = ({
  name,
  placeholder,
  value,
  handleOnInput,
  handleOnClick,
  error
}) => {
  return (
    <Container>
      <SearchInput>
        <StyledFloatingLabel>
          <StyledSearchInput
            name={name}
            placeholder={placeholder}
            type="text"
            value={value}
            onInput={handleOnInput}
          />
          <StyledLabel>{placeholder}</StyledLabel>
        </StyledFloatingLabel>
        <StyledSearchIcon onClick={handleOnClick} />
      </SearchInput>
      {error && <Error message={error} />}
    </Container>
  );
};

InputWithSearch.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleOnInput: PropTypes.func,
  handleOnClick: PropTypes.func,
  error: PropTypes.string
};

Input.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  readonly: PropTypes.bool,
  error: PropTypes.string,
  type: PropTypes.string,
  onBlur:PropTypes.func,
  onFocus:PropTypes.func
};
