import styled from "styled-components";
import React from "react";
import Background from "../static/background.png";
import PropTypes from "prop-types";

const Image = styled.img`
  width: ${(p) => p.width};
  position: ${(p) => p.position};
`;

export const BackgroundImage = (props) => {
  return (
    <Image src={Background} width={props.width} position={props.position} />
  );
};
BackgroundImage.propTypes = {
  width: PropTypes.string,
  position: PropTypes.string
};
