var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, j = Object.getOwnPropertySymbols(s); i < j.length; i++) {
            if (e.indexOf(j[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, j[i]))
                t[j[i]] = s[j[i]];
        }
    return t;
};
import React from 'react';
const ChevronDown = (_a) => {
    var { size = 20, stroke = 'currentColor' } = _a, rest = __rest(_a, ["size", "stroke"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", Object.assign({ width: size, height: size, viewBox: "0 0 24 24", fill: "none", stroke: stroke, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round", className: "side-navigation-panel-select-option-icon side-navigation-panel-select-option-icon-chevron-down" }, rest),
            React.createElement("path", { d: "M6 9l6 6 6-6" }))));
};
export const ChevronDownIcon = React.memo(ChevronDown);
const ChevronUp = (_a) => {
    var { size = 20, stroke = 'currentColor' } = _a, rest = __rest(_a, ["size", "stroke"]);
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", Object.assign({ width: size, height: size, viewBox: "0 0 24 24", fill: "none", stroke: stroke, strokeWidth: 1.5, strokeLinecap: "round", strokeLinejoin: "round", className: "side-navigation-panel-select-option-icon side-navigation-panel-select-option-icon-chevron-up" }, rest),
            React.createElement("path", { d: "M18 15l-6-6-6 6" }))));
};
export const ChevronUpIcon = React.memo(ChevronUp);