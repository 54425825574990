import styled from "styled-components";
import SkewlLogo from "../static/skewl-logo.svg";

export const HeaderLogo = styled.img.attrs((props) => ({
  src: props.img || SkewlLogo
}))`
  height: ${(props) => props.height || 64}px;
  width: ${(props) => props.width || 154}px;
  margin: 15px 0 0 23px;
`;
