import React, { useState, useEffect, useReducer } from "react";
import * as d3 from "d3";
import styled from "styled-components";
import PropTypes from "prop-types";

const Path = styled.path`
  fill: ${(props) => props.color};
  cursor: pointer;
`;
const Text = styled.text`
  font-family: Open Sans;
  font-weight: Bold;
  font-size: 12px;
  text-align: center;
  fill: ${(props) => props.fill};
`;

const color = {
  Car: "#A1DAF9",
  Cycle: "#F46363",
  Walk: "#5FDD97",
  "Public Transportation": "#FF9138",
  Morning: "#a2d5c6",
  Evening: "#077b8a"
};

const Arc = ({ arcData, index, onClick, outerRadius, total }) => {
  const [radiusAdd, setRadiusAdd] = useState(0);
  const [tooltipOpacity, setTooltipOpacity] = useState(0);
  const toolTipText = arcData.data.data_type + " " + arcData.data.total;
  const arc = d3
    .arc()
    .innerRadius((outerRadius * 70) / 100 + radiusAdd / 2)
    .outerRadius(outerRadius + radiusAdd);

  function mouseOver() {
    setTooltipOpacity(1);
    setRadiusAdd(5);
  }

  function mouseOut() {
    setTooltipOpacity(0);
    setRadiusAdd(0);
  }

  return (
    <g key={index}>
      <Path
        key={index + 1}
        d={arc(arcData)}
        color={color[arcData.data.data_type]}
        onMouseOver={mouseOver}
        onMouseOut={mouseOut}
        onClick={() => onClick(arcData)}
      />
      <Text
        key={index + 2}
        transform={"translate(" + arc.centroid(arcData) + ")"}
        //since you have an array of arc generators I used i to find the arc

        dy="0.35em"
        textAnchor="middle"
        fill="#ffffff"
      >
        {+parseFloat((arcData.data.total / total) * 100).toFixed(2) + "%"}
      </Text>
      <rect
        key={index + 3}
        transform={"translate(" + arc.centroid(arcData) + ")"}
        id="tool-tip"
        opacity={tooltipOpacity}
        height={30}
        width={150}
        rx={3}
        ry={3}
        fill="#5d6f88"
      />
      <Text
        key={index + 4}
        x={75}
        id={arcData.data.data_type + "arc-tool-tip"}
        opacity={tooltipOpacity}
        transform={"translate(" + arc.centroid(arcData) + ")"}
        //since you have an array of arc generators I used i to find the arc

        dy="0.35em"
        y="15"
        textAnchor="middle"
        fill="#ffffff"
      >
        {toolTipText}
      </Text>
    </g>
  );
};
Arc.propTypes = {
  arcData: PropTypes.object,
  onClick: PropTypes.func,
  outerRadius: PropTypes.number,
  total: PropTypes.number,
  index: PropTypes.number
};

function useDrillableData(data, dimension) {
  const initialState = {
    renderData: data ? data.transportation_data : [],
    stack: [],
    startAngle: 0,
    total: data ? data.total : 0,
    dimension: dimension,
    resetData: false
  };

  return useReducer((state, action) => {
    switch (action.type) {
      case "drilldown":
        return {
          renderData: state.renderData[action.index].children
            ? state.renderData[action.index].children
            : action.resetData
            ? action.formattedData.transportation_data
            : [state.renderData[action.index]],
          stack: state.renderData[action.index].children
            ? [...state.stack, state.renderData]
            : [],
          startAngle: state.renderData[action.index].children
            ? action.startAngle
            : 0,
          total: action.resetData
            ? action.formattedData.total
            : state.renderData[action.index].total,
          dimension: state.renderData[action.index].data_type,
          resetData: action.depth === 2 && !action.resetData ? true : false
        };
      case "drillup":
        if (state.stack.length > 0) {
          return {
            renderData: state.stack.slice(-1)[0],
            stack: state.stack.slice(0, -1),
            startAngle: state.startAngle
          };
        } else {
          return state;
        }
      case "update":
        return {
          renderData: action.formattedData.transportation_data,
          stack: [],
          startAngle: 0,
          total: action.formattedData.total,
          dimension: undefined,
          resetData: false
        };
      default:
        return state;
    }
  }, initialState);
}

function formatData(rawData) {
  let formattedData = {};
  let cumulative_count = 0;
  let transportation_data = [];
  rawData &&
    rawData.map((data) => {
      data.school_run.map((s, index) => {
        let filter =
          transportation_data &&
          transportation_data.filter(
            (d) => d.data_type === s.transportation_mode
          );

        let total = 0;
        let session_array = [];
        let transportation =
          filter.length > 0
            ? filter[0]
            : {
                id: index,
                index: index,
                data_type: s.transportation_mode,
                depth: 1
              };
        let child_index = index === 0 ? 1 * 10 : (index + 1) * 10;
        s.children.map((s) => {
          let session_filter = transportation.children
            ? transportation.children.filter((d) => d.data_type === s.session)
            : [];
          if (session_filter.length > 0) {
            session_filter[0].total = session_filter[0].total + s.count;
          } else {
            session_array.push({
              id: child_index,
              index: child_index,
              data_type: s.session,
              total: s.count,
              depth: 2
            });
          }
          child_index = child_index + 1;
          total = total + s.count;
        });
        if (filter.length > 0) {
          transportation.total = transportation.total + total;
        } else {
          transportation["total"] = total;
          transportation["children"] = session_array;
          transportation_data.push(transportation);
        }

        cumulative_count = cumulative_count + total;
      });
    });
  formattedData["total"] = cumulative_count;
  formattedData["depth"] = 0;
  formattedData["transportation_data"] = transportation_data;
  return formattedData;
}

export const DonutChart = (props) => {
  const [state, dispatch] = useDrillableData();
  const { renderData, startAngle, total, dimension, resetData } = state;
  const [formattedData, setFormattedData] = useState();

  const [percentVisible, setPercentVisible] = useState(0);

  const drilldown = (d) => {
    const { index, startAngle, data } = d;
    let depth = data.depth;
    props.setFilter({
      type:
        depth === 1
          ? ["transportation_mode"]
          : ["transportation_mode", "session"],
      value: depth === 1 ? [d.data.data_type] : [dimension, d.data.data_type]
    });
    if (resetData) {
      props.reset();
    }
    dispatch({
      type: "drilldown",
      index,
      startAngle,
      depth,
      formattedData,
      resetData
    });
  };

  const getLegendPosition = (cxDisplacement, isText) => {
    let legendStartPosition = props.width - (props.width * 95) / 100;
    let legentNewPosition =
      legendStartPosition + cxDisplacement * ((props.width * 23) / 100);
    return isText ? legentNewPosition + 10 : legentNewPosition;
  };

  const pie = d3
    .pie()
    .startAngle(startAngle)
    .endAngle(startAngle + percentVisible * Math.PI * 2)
    .value((d) => d.total);

  useEffect(() => {
    let formattedData = formatData(props.data);
    setFormattedData(formattedData);
    dispatch({ type: "update", formattedData });
  }, [props.data]);

  useEffect(() => {
    d3.selection()
      .transition("pie-reveal")
      .duration(10000)
      .ease(d3.easeSinInOut)
      .tween("percentVisible", () => {
        const percentInterpolate = d3.interpolate(0, 100);
        return (t) => setPercentVisible(percentInterpolate(t));
      });
  }, [renderData]);
  return (
    <svg
      width={props.width}
      height={props.height}
      style={{ overflow: "visible" }}
    >
      <g
        transform={`translate(${props.width / 2}, ${props.height / 2})`}
        id="g-donut"
      >
        {pie(renderData).map((d) => {
          return (
            <Arc
              arcData={d}
              key={d.data.id}
              index={d.data.index}
              onClick={drilldown}
              outerRadius={(props.width * 65) / 100 / 2}
              total={total}
            />
          );
        })}
        {/* <g>
          {dimension ? (
            <Text
              x={-("Total " + dimension).length * 2}
              fill="#5D6F88"
              text-anchor="middle"
            >
              {"Total " + dimension}
            </Text>
          ) : (
            <Text x={-"Total".length * 2} fill="#5D6F88" text-anchor="middle">
              {"Total"}
            </Text>
          )}

          <Text
            y="20"
            x={-(total.toString().length * 2)}
            fill="#5D6F88"
            text-anchor="middle"
          >
            {total}
          </Text>
        </g> */}
      </g>
      <g
        transform={`translate(0,${props.height - (props.height * 102) / 100})`}
      >
        {renderData.map((d, index) => (
          <g key={d.id}>
            <circle
              key={index}
              cx={`${getLegendPosition(index, false)}`}
              cy={`${props.height}`}
              r={7}
              fill={color[d.data_type]}
            />
            <Text
              key={index + 1}
              x={`${getLegendPosition(index, true)}`}
              y={`${props.height + 3}`}
              fill="#5D6F88"
              alignment-baseline="central"
            >
              {d.data_type}
            </Text>
          </g>
        ))}
      </g>
    </svg>
  );
};

DonutChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  setFilter: PropTypes.func,
  reset: PropTypes.func
};
