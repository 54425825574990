import React, { Fragment, useState } from "react";
import Background from "../static/login.png";
import Kid from "../static/kids.png";
import { HeaderLogo, Title, PrimaryButton, Footer,Error,  Loader,
  BackDrop } from "../components";
import styled from "styled-components";
import { Password } from "../hoc/Login";
import SkewlAuth from "../services/skewl-auth";
import { useHistory } from "react-router-dom";
import { Constants } from "../utils/constants";

const Content = styled.div`
  background-image: url(${Background});
  background-size: cover;
  padding-top: 10%;
  padding-left: 15%;
  display: flex;
  justify-content: flex-start;
`;

const ChangePasswordText = styled.h1`
  height: 70px;
  width: 426px;
  color: #0099ff;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 70px;
  margin: 0 0 14px 0;
`;

const ChangePasswordWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChangePasswordSection = styled.div`
  height: 525px;
  width: 445px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 25px;
`;

const StyledImage = styled.div`
  padding-left: 25%;
`;

// const HelpText = styled.div`
//   font-size:10px;
//   font-family:Open Sans;
//   color:rgb(51, 51, 51);
`;
// const HelpIconContainer = styled.div`
//   width:20px;
//   height:20px;
// `;

export const ChangePassword = () => {
  document.title = "Skewl | Change Password";
  let history = useHistory();
  const [newpassword, setNewPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const[error,setError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const[showLoader,setShowLoader] = useState(false);
  const[confirmPasswordError,setConfirmPasswordError] = useState("");

  const handleNewPassword = (value) => {
      setPasswordError("");
      setError("")
      setNewPassword(value);
 
  };

  const handleConfirmPassword = (e) => {
        setConfirmPasswordError("")
        setError("")
        setConfirmPassword(e);

  };

  const handleChangePassword = async (event) => {
    try
    {
      setShowLoader(true);
      if (passwordError === "" && confirmPasswordError === "" && newpassword === confirmpassword && error === "")
      {
        await SkewlAuth.completeNewPassword(newpassword);
        setShowLoader(false);
        history.push(
           "/", {
            hasPasswordReset: true
          }
        );
      }
      else if (newpassword && confirmpassword && newpassword != confirmpassword)
      {
        setShowLoader(false);
        setError("The New Password and Confirm Password are not matching")
      }
    } catch (error)
    {
      console.log(error);
      setError("Error while resetting the password")
    }
    event.stopPropagation()
    event.preventDefault();
  };
const handleOnblur= (type)=>{
  switch(type){
    case 1:
      if(newpassword === ""){
        setPasswordError("Please enter new password. "+Constants.messages.PASSWORD_HELP_TEXT);
      }
      break;
    case 2:
      if(confirmpassword === ""){
        setConfirmPasswordError("Please enter confirm password. "+Constants.messages.CHANGE_PASSWORD_HELP_TEXT)
      }
      break;
  }

}

  return (
    <Fragment>
      <HeaderLogo />
      <Content>
        <BackDrop showBackdrop={showLoader} setShowBackdrop={setShowLoader}>
        <Loader showLoader={showLoader}/>
        </BackDrop>
        <ChangePasswordWrapper>
          <ChangePasswordText>Welcome Back!</ChangePasswordText>
          <ChangePasswordSection>
            <Title title="Change Password" />
            <Error message={error} />
            <Password
              placeholder="New Password"
              value={newpassword}
              handleOnInput={handleNewPassword}
              error={passwordError}
              onBlur={()=>handleOnblur(1)}
            />
            <Password
              placeholder="Confirm Password"
              value={confirmpassword}
              handleOnInput={handleConfirmPassword}
              error={confirmPasswordError}
              onBlur={()=>handleOnblur(2)}            
            />
            <PrimaryButton
              type="submit"
              value="Submit"
              handleClick={handleChangePassword}
              width={"305px"}
              height={"48px"}
              fontSize={"18px"}
              borderRadius={"6px"}
             
            />
            <Footer />
          </ChangePasswordSection>
        </ChangePasswordWrapper>
        <StyledImage>
          <img src={Kid} />
        </StyledImage>
      </Content>
    </Fragment>
  );
};

// ChangePassword.propTypes = {
//   user: PropTypes.object,
// };
