import React, { useState, useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
import SkewlAuth from "../../services/skewl-auth";

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const [isSessionValid, setIsSessionValid] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function isSessionValid() {
      let sessionValidity = await SkewlAuth.isAuthenticated();
      setIsSessionValid(sessionValidity);
      setIsLoading(false);
    }
    isSessionValid();
  }, []);

  return isLoading ? null : (
    <Route
      {...rest}
      render={(props) => {
        if (isSessionValid) {
          return <Component {...props} {...rest} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
                state: {
                  sessionExpired: true,
                  from: props.location
                }
              }}
            />
          );
        }
      }}
    />
  );
};
ProtectedRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object
};
