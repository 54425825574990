import PropTypes from "prop-types";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Clock from "../static/clock.svg";

const Select = styled.select`
  border: 0px;
  font-family: Open Sans;
  font-size: 12px;
  appearance: none;
  -ms-appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  width: 85px;
  background-image: url(${Clock});
  background-position: 100% 0%;
  background-repeat: no-repeat;
  outline: 0px;
  padding: 0 5px 0 0;
  position: absolute;
`;
export const TimePicker = ({
  defaultValue,
  onChange,
  name,
  beginLimit,
  endLimit,
  step,
  ...rest
}) => {
  const [options, setOptions] = useState([]);
  useEffect(() => {
    let timeValue = beginLimit || "12:00";
    let lastValue;
    let endLimit1 = endLimit || "11:59";
    let optionsArray = [];
    let optionValue;
    while (isEarlierThanEndLimit(timeValue, endLimit1, lastValue)) {
      lastValue = timeValue;
      timeValue = moment(timeValue, "h:mmA")
        .add(step, "minutes")
        .format("h:mmA");
      let hours = moment(timeValue, "H:mmA").hours();
      let minutes = moment(timeValue, "H:mmA").minutes();
      optionValue = hours * 60 + minutes;
      optionsArray.push(
        <option
          key={optionValue}
          value={optionValue}
          selected={optionValue === parseInt(defaultValue)}
        >
          {timeValue}
        </option>
      );
    }
    setOptions(optionsArray);
  }, [defaultValue]);
  const setSize = (event) => {
    event.target.size = 5;
    //if (event.target.style.zIndex === 0) {
    event.target.style.zIndex = 2;
    //}
  };
  const resetSize = (event) => {
    event.target.size = 1;
    //if (event.target.style.zIndex === 0) {
    event.target.style.zIndex = 0;
    //}
  };
  const isEarlierThanEndLimit = (timeValue, endLimit, lastValue) => {
    let timeValueIsEarlier =
      moment(timeValue, "h:mmA").diff(moment(endLimit, "h:mmA")) < 0;
    let timeValueIsLaterThanLastValue =
      lastValue === undefined
        ? true
        : moment(lastValue, "h:mmA").diff(moment(timeValue, "h:mmA")) < 0;
    return timeValueIsEarlier && timeValueIsLaterThanLastValue;
  };
  return (
    <Select
      onChange={onChange}
      name={name}
      onFocus={setSize}
      onBlur={resetSize}
      {...rest}
    >
      {options}
    </Select>
  );
};

TimePicker.propTypes = {
  defaultValue: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  beginLimit: PropTypes.string,
  endLimit: PropTypes.string,
  step: PropTypes.number
};
