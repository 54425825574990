import axios from "axios";
import SkewlAuth from "./skewl-auth";
import { Constants } from "../utils/constants";

const SKEWL_HOST = process.env.REACT_APP_API_URL;

export const validateSession = async (session_id) => {
  try {
    let apiToken = await SkewlAuth.getToken();
    const config = {
      method: "get",
      url: `${SKEWL_HOST}/v1/validate/session/${encodeURIComponent(
        session_id
      )}`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
    };
    const res = await axios(config);
    if (res.status === Constants.http_status_codes.SUCCESS) {
      return res.data;
    } else if (res.status === Constants.http_status_codes.UNAUTHROISED) {
      return res.data;
    }
  } catch (error) {
    console.log(
      "Error occurred while validating the session: ",
      session_id
    );

    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      return { session_validity: false };
    }else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      return { session_validity: false };
    } 
    else {
      return [];
    }
  }
};
