import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Avatar, PrimaryButton, SecondaryButton } from "../../components";
import PropTypes from "prop-types";
import { ReactComponent as DoB } from "../../static/calendar.svg";
import { ReactComponent as Student } from "../../static/student.svg";
import { ReactComponent as Phone } from "../../static/phone.svg";
import { RelationAddress } from "../FamilyProfile";
import { ReactComponent as Home } from "../../static/home.svg";
import { ReactComponent as Close } from "../../static/close.svg";
import ChildSVG from "../../static/child.svg";
import POSVG from "../../static/profile-owner.svg";
import { Constants } from "../../utils/constants";
import { Storage } from "aws-amplify";

const ModalWrapper = styled(motion.div)`
  width: fit-content;
  height: 500px;
  border-radius: 15px;
  background-color: #fff;
  flex-direction: column;
`;
const ColumnContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
`;

const ProfileColumnContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  width: 40%;
  border-radius: 15px;
  height: 190px;
  background-color: #ceefff;
`;
const AddressContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const RowContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
`;
const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

const Data = styled.span`
  color: #303133;
  font-family: Open Sans;
  font-size: 13px;
  width: 100%;
  text-align: center;
`;

const Name = styled.span`
  color: #303133;
  font-family: Open Sans;
  font-size: 12.6px;
  width: 100%;
  font-weight: bold;
  margin-top: 5px;
  text-align: center;
`;

const ModalTitle = styled.span`
  color: #0099ff;
  font-size: 20px;
  font-weight: bold;
  margin: 5px 0 0 0;
  font-family: Open Sans;
`;

const TextArea = styled.textarea`
  color: #303133;
  font-family: Open Sans;
  font-size: 13px;
  width: 70%;
  border-radius: 15px;
  width: 100%;
  border-color: ${(props) => (props.error ? "#ff0000" : "#d1d1d1")};
`;

const ModalContainer = styled.div`
  margin-top: 30px;
  margin-left: 10px;
  margin-right: 10px;
  height: 70%;
`;
const AvatarContainer = styled.div`
  margin-top: -30px;
`;
const Dummy = styled.div`
  height: 50px;
  display: flex;
`;

const CircleCloseShape = styled.span`
  height: 25px;
  width: 25px;
  background-color: #ffffff;
  border-radius: 50%;
  display: inline-block;
`;
const CloseButtonConatiner = styled.div`
  display: flex;
  margin-top: -10px;
`;
const modal = {
  initial: { opacity: 0, scale: 0.75 },
  animate: { opacity: 1, scale: 1, transition: 3 },
  exit: { opacity: 0, scale: 0, transition: 3 }
};

export const Modal = (props) => {
  let data = props.data;
  const [comments, setComments] = useState("");
  const [childImage, setChildImage] = useState();
  const [poImage, setPOImage] = useState();
  const close = () => {
    props.setShowBackdrop(false);
    props.setShowModal(false);
    props.setError(false);
  };

  const handleChange = (event) => {
    setComments(event.target.value);
  };

  useEffect(() => {
    async function setImages() {
      try {
        if (data) {
          if (data.profile_owner.photo_url) {
            Storage.configure({
              customPrefix: {
                public: Constants.profile_image_folders.profile_owners
              }
            });

            let poImg = await Storage.get(data.profile_owner.photo_url, {
              level: "public",
              expires: 60
            });

            setPOImage(poImg);
          }
          if (data.child.photo_url) {
            Storage.configure({
              customPrefix: {
                public: Constants.profile_image_folders.children
              }
            });
            let childImg = await Storage.get(data.child.photo_url, {
              level: "public",
              expires: 60
            });

            setChildImage(childImg);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    setImages();
  }, [data]);

  return (
    props.showModal && (
      <ModalWrapper
        id="Verification-Modal-Wrapper"
        variants={modal}
        initial="initial"
        animate="animate"
        exit="exit"
      >
        <TitleContainer>
          <div />
          <ModalTitle>Child Details</ModalTitle>
          <CloseButtonConatiner onClick={close}>
            <CircleCloseShape>
              <div>
                <Close />
              </div>
            </CircleCloseShape>
          </CloseButtonConatiner>
        </TitleContainer>
        <ModalContainer>
          <RowContainer>
            <ProfileColumnContainer>
              <AvatarContainer>
                <Avatar size="m" img={childImage ? childImage : ChildSVG} />
              </AvatarContainer>
              <Name>
                {data.child.first_name} {data.child.last_name}
              </Name>
              <ColumnContainer>
                <DoB />
                <Data>{data.child.dob}</Data>
              </ColumnContainer>
              <ColumnContainer>
                <Student />
                <Data>{data.child.key_stage_desc}</Data>
              </ColumnContainer>
            </ProfileColumnContainer>
            <ColumnContainer>
              <Dummy />
              <Data>{data.profile_owner.relationship}</Data>
              <RelationAddress />
              <Home />
            </ColumnContainer>
            <ProfileColumnContainer>
              <AvatarContainer>
                <Avatar size="m" img={poImage ? poImage : POSVG} />
              </AvatarContainer>
              <Name>
                {data.profile_owner.first_name} {data.profile_owner.last_name}
              </Name>
              <ColumnContainer>
                <Phone />
                <Data>{data.profile_owner.phone_number}</Data>
              </ColumnContainer>
            </ProfileColumnContainer>
          </RowContainer>
          <RowContainer>
            <AddressContainer>
              <Data>{data.address_details}</Data>
            </AddressContainer>
          </RowContainer>
          <ColumnContainer>
            <TextArea
              rows="5"
              value={comments}
              onChange={handleChange}
              placeholder="Please give additional comments here"
              error={props.error}
            />
          </ColumnContainer>
        </ModalContainer>
        <RowContainer>
          <ColumnContainer>
            <SecondaryButton
              type="button"
              value="Unmatched"
              handleClick={() => props.submitData(data.id, false, comments)}
              width={"100px"}
              height={"40px"}
              fontSize={"15px"}
              borderRadius={"6px"}
            />
          </ColumnContainer>
          <ColumnContainer>
            <PrimaryButton
              type="button"
              value="Matched"
              handleClick={() => props.submitData(data.id, true, comments)}
              width={"100px"}
              height={"40px"}
              fontSize={"15px"}
              borderRadius={"6px"}
            />
          </ColumnContainer>
        </RowContainer>
      </ModalWrapper>
    )
  );
};
Modal.propTypes = {
  data: PropTypes.object,
  setShowModal: PropTypes.func,
  submitData: PropTypes.func,
  showModal: PropTypes.bool,
  setShowBackdrop: PropTypes.func,
  error: PropTypes.any,
  setError: PropTypes.func
};
