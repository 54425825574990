import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dropdown } from "../../components";
import { ReactComponent as FilterIcon } from "../../static/filter.svg";
import { motion } from "framer-motion";
import PropTypes from "prop-types";

const FilterContainer = styled.div`
  width: 100%;
  background-color: #ceefff;
  height: 143px;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 80%;
  background-color: #ceefff;
  margin: auto;
  height: 143px;
`;
const Filter = styled(motion.div)`
  height: 30px;
  width: 70px;
  border-radius: 3px;
  background-color: #006eff;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
`;

const FilterText = styled.span`
  color: #ffffff;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
`;

export const FilterOptions = ({ filteredData, masterData }) => {
  const [yearOptions, setYearOptions] = useState([]);
  const [classMasterOptions, setclassMasterOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({
    yearValue: "000",
    sectionValue: "000",
    yearLabel: "Select Year",
    sectionLabel: "Select Section",
    disableSection: true,
    classOptions: [{ value: "000", label: "Select Section" }],
  });

  useEffect(() => {
    buildFilterData(masterData);
  }, [masterData]);

  const buildFilterData = (filterData) => {
    var result = {};
    var yearOptions = [];
    yearOptions.push({ value: "000", label: "Select Year" });
    var classMasterOptions = [];
    filterData &&
      filterData.map((year) => {
        yearOptions = buildYearOptions(year, yearOptions);
        classMasterOptions.push(buildMasterClassOptions(year));
      });
    setYearOptions(yearOptions);
    setclassMasterOptions(classMasterOptions);
    return result;
  };

  const buildYearOptions = (year, yearOptions) => {
    var option = {
      value: "" + year.education_stage,
      label: year.year,
    };
    yearOptions.push(option);
    return yearOptions;
  };

  const buildMasterClassOptions = (year) => {
    var classMaster = {};
    var sections = [];
    sections.push({ value: "000", label: "Select Section" });
    classMaster["year"] = "" + year.education_stage;
    year.sections.map((section) => {
      sections.push({
        value: "" + section.id,
        label: section.desc,
      });
    });
    classMaster["class"] = sections;
    return classMaster;
  };
  useEffect(() => {
    var sectionOption = classMasterOptions.filter(
      (masterOptions) => masterOptions.year === selectedOptions.yearValue
    );
    setSelectedOptions(() => ({
      yearValue: selectedOptions.yearValue,
      sectionValue: "000",
      yearLabel: selectedOptions.yearLabel,
      sectionLabel: "Select Section",
      disableSection: selectedOptions.yearValue === "000" ? true : false,
      classOptions:
        sectionOption && sectionOption.length > 0
          ? sectionOption[0].class
          : selectedOptions.classOptions,
    }));
  }, [selectedOptions.yearValue]);

  const handleYearSelection = (chosenOption) => {
    if (chosenOption.value != selectedOptions.yearValue) {
      setSelectedOptions(() => ({
        yearValue: chosenOption.value,
        sectionValue:
          chosenOption.value === "000" ? "000" : selectedOptions.sectionValue,
        yearLabel: chosenOption.label,
        sectionLabel:
          chosenOption.value === "000"
            ? "Select Section"
            : selectedOptions.sectionLabel,
        disableSection: chosenOption.value === "000" ? true : false,
      }));
    }
  };
  const applyFilter = () => {
    filteredData(
      selectedOptions.yearValue != "000" ? selectedOptions.yearValue : "",
      selectedOptions.sectionValue != "000" ? selectedOptions.sectionValue : ""
    );
  };
  const handleSectionSelection = (chosenOption) => {
    var sectionOption = classMasterOptions.filter(
      (masterOptions) => masterOptions.year === selectedOptions.yearValue
    );
    setSelectedOptions(() => ({
      yearValue: selectedOptions.yearValue,
      yearLabel: selectedOptions.yearLabel,
      sectionValue: chosenOption.value,
      sectionLabel: chosenOption.label,
      disableSection: false,
      classOptions:
        sectionOption && sectionOption.length > 0
          ? sectionOption[0].class
          : selectedOptions.classOptions,
    }));
  };

  return (
    <FilterContainer>
      <FilterWrapper>
        <Dropdown
          placeholder={selectedOptions.yearLabel}
          items={yearOptions}
          onChange={handleYearSelection}
          value={selectedOptions.yearValue}
        />

        <Dropdown
          placeholder={selectedOptions.sectionLabel}
          items={selectedOptions.classOptions}
          isDisabled={selectedOptions.disableSection}
          onChange={handleSectionSelection}
          value={selectedOptions.sectionValue}
        />
        <Filter
          role="button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          onClick={applyFilter}
        >
          <FilterIcon />
          <FilterText>Filter</FilterText>
        </Filter>
      </FilterWrapper>
    </FilterContainer>
  );
};
FilterOptions.propTypes = {
  filteredData: PropTypes.func,
  masterData: PropTypes.array,
};
