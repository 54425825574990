import axios from "axios";
import SkewlAuth from "./skewl-auth";
import { Constants } from "../utils/constants";

const SKEWL_HOST = process.env.REACT_APP_API_URL;

export const verifyProfile = async (
  child_id,
  data_verification_status,
  comments
) => {
  try {
    let apiToken = await SkewlAuth.getToken();
    let session_id = sessionStorage.getItem("session_id");
    const data = {
      child_id: child_id,
      verified_time: new Date(),
      session_id: encodeURIComponent(session_id),
      data_verification_status: data_verification_status,
      comments: comments,
    };
    const config = {
      method: "put",
      url: `${SKEWL_HOST}/v1/child/verify`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
      data: data,
    };

    const res = await axios(config);
    if (res.status === Constants.http_status_codes.UPDATE_SUCCESS) {
      updateSession(res.data.result);
      return res.data;
    } else if (res.status === Constants.http_status_codes.NO_CONTENT) {
      updateSession(res.data.result);
      return { ...data, error: "No child found" };
    } else {
      throw new Error("unknown error");
    }
  } catch (error) {
    console.log("Error while approving child: ", child_id, error);
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.PROFILE_VERIFICATION_UPDATE_100;
    }
    return {
      error: errorCode,
    };
  }
};

const updateSession = (result) => {
  if (result && result.session_id) {
    sessionStorage.setItem("session_id", result.session_id);
  }
};
