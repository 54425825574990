import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Dropdown } from "../../components";
import { ReactComponent as FilterIcon } from "../../static/filter.svg";
import { ReactComponent as StartIcon } from "../../static/start.svg";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import DatePicker from "react-modern-calendar-datepicker";
import { ReactComponent as Calendar } from "../../static/calendar.svg";

const FilterContainer = styled.div`
  width: 80%;
  background-color: #ceefff;
  height: 143px;
  position: absolute;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-color: #ceefff;
  margin: auto;
  height: 143px;
  .custom-calendar {
    --cl-color-disabled: #959393 !important;
    --cl-color-error: #dfdfdf !important;
    background: #ceefff !important;
    box-shadow: 0 1em 3em #ceefff;
    font-size: 9px !important;
    font-family: Open Sans !important;
  }
  .custom-today-day {
    color: #959393 !important;
    border: 1px solid #959393 !important;
  }

  .custom-today-day::after {
    visibility: hidden; /* hide small border under the text */
  }
`;

const GenerateOTP = styled(motion.div)`
  height: 30px;
  width: 150px;
  border-radius: 3px;
  background: ${(p) => p.background};
  border: ${(p) => p.border};
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: space-around;
  opacity: ${(p) => p.opacity};
`;

const Filter = styled(motion.div)`
  height: 30px;
  width: 118px;
  border-radius: 3px;
  background: ${(p) => p.background};
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: ${(p) => p.opacity};
  justify-content: space-around;
`;

const FilterText = styled.span`
  color: #ffffff;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
`;
const CalendarContainer = styled.div`
  display: flex;
  width: 80%;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #006eff;
`;
const ButtonText = styled.span`
  height: 16px;
  color: ${(p) => p.color};
  font-family: Open Sans;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 16px;
`;

export const Filters = (props) => {
  const [yearOptions, setYearOptions] = useState([]);
  const [classMasterOptions, setclassMasterOptions] = useState([]);
  const [selectedDate, setSelectedDate] = useState({
    day:
      new Date().getDay() === 0
        ? new Date().getDate() + 1
        : new Date().getDate(),
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1
  });
  const [selectedOptions, setSelectedOptions] = useState({
    yearValue: "000",
    sectionValue: "000",
    yearLabel: "Select Year",
    sectionLabel: "Select Section",
    disableSection: true,
    classOptions: [{ value: "000", label: "Select Section" }]
  });

  useEffect(() => {
    buildFilterData(props.masterData);
  }, [props.masterData]);

  const buildFilterData = (filterData) => {
    var result = {};
    var yearOptions = [];
    yearOptions.push({ value: "000", label: "Select Year" });
    var classMasterOptions = [];
    filterData &&
      filterData.map((year) => {
        yearOptions = buildYearOptions(year, yearOptions);
        classMasterOptions.push(buildMasterClassOptions(year));
      });
    setYearOptions(yearOptions);
    setclassMasterOptions(classMasterOptions);
    setSelectedOptions({
      yearValue: "000",
      sectionValue: "000",
      yearLabel: "Select Year",
      sectionLabel: "Select Section",
      disableSection: true,
      classOptions: [{ value: "000", label: "Select Section" }]
    });
    return result;
  };

  const buildYearOptions = (year, yearOptions) => {
    var option = {
      value: "" + year.education_stage,
      label: year.year
    };
    yearOptions.push(option);
    return yearOptions;
  };

  const buildMasterClassOptions = (year) => {
    var classMaster = {};
    var sections = [];
    sections.push({ value: "000", label: "Select Section" });
    classMaster["year"] = "" + year.education_stage;
    year.sections.map((section) => {
      sections.push({
        value: "" + section.id,
        label: section.desc
      });
    });
    classMaster["class"] = sections;
    return classMaster;
  };
  useEffect(() => {
    var sectionOption = classMasterOptions.filter(
      (masterOptions) => masterOptions.year === selectedOptions.yearValue
    );
    setSelectedOptions(() => ({
      yearValue: selectedOptions.yearValue,
      sectionValue: "000",
      yearLabel: selectedOptions.yearLabel,
      sectionLabel: "Select Section",
      disableSection: selectedOptions.yearValue === "000" ? true : false,
      classOptions:
        sectionOption && sectionOption.length > 0
          ? sectionOption[0].class
          : selectedOptions.classOptions
    }));
  }, [selectedOptions.yearValue]);

  const handleYearSelection = (chosenOption) => {
    if (chosenOption.value != selectedOptions.yearValue) {
      setSelectedOptions(() => ({
        yearValue: chosenOption.value,
        sectionValue:
          chosenOption.value === "000" ? "000" : selectedOptions.sectionValue,
        yearLabel: chosenOption.label,
        sectionLabel:
          chosenOption.value === "000"
            ? "Select Section"
            : selectedOptions.sectionLabel,
        disableSection: chosenOption.value === "000" ? true : false
      }));
    }
  };
  const applyFilter = () => {
    props.filteredData(
      selectedOptions.yearValue != "000" ? selectedOptions.yearValue : "",
      selectedOptions.sectionValue != "000" ? selectedOptions.sectionValue : ""
    );
  };
  const handleSectionSelection = (chosenOption) => {
    var sectionOption = classMasterOptions.filter(
      (masterOptions) => masterOptions.year === selectedOptions.yearValue
    );
    setSelectedOptions(() => ({
      yearValue: selectedOptions.yearValue,
      yearLabel: selectedOptions.yearLabel,
      sectionValue: chosenOption.value,
      sectionLabel: chosenOption.label,
      disableSection: false,
      classOptions:
        sectionOption && sectionOption.length > 0
          ? sectionOption[0].class
          : selectedOptions.classOptions
    }));
  };
  const onDateChange = (value) => {
    if (
      value.year != selectedDate.year ||
      value.day != selectedDate.day ||
      value.month != selectedDate.month
    ) {
      props.pickupDataByDate(value.year + "-" + value.month + "-" + value.day);
      setSelectedDate(value);
    }
  };

  const renderCustomInput = ({ ref }) => (
    <CalendarContainer>
      <Calendar width={"30"} height={"30"} />
      <input
        readOnly
        ref={ref}
        value={
          selectedDate.day + "-" + selectedDate.month + "-" + selectedDate.year
        }
        placeholder="Today"
        style={{
          height: "30px",
          width: "50%",
          fontSize: "12px",
          color: "#006eff",
          border: "none",
          background: "none",
          outline: "none"
        }}
        className="my-custom-input-class" // a styling class
      />
    </CalendarContainer>
  );

  return (
    <FilterContainer>
      <FilterWrapper>
        <Dropdown
          placeholder={selectedOptions.yearLabel}
          items={yearOptions}
          onChange={handleYearSelection}
          value={selectedOptions.yearValue}
        />

        <Dropdown
          placeholder={selectedOptions.sectionLabel}
          items={selectedOptions.classOptions}
          isDisabled={selectedOptions.disableSection}
          onChange={handleSectionSelection}
          value={selectedOptions.sectionValue}
        />

        <Filter
          role="button"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
          background={"#006eff"}
          onClick={applyFilter}
        >
          <FilterIcon />
          <FilterText>Filter</FilterText>
        </Filter>

        <DatePicker
          shouldHighlightWeekends
          value={selectedDate}
          colorPrimary="#006eff"
          calendarClassName="custom-calendar"
          calendarTodayClassName="custom-today-day"
          onChange={onDateChange}
          renderInput={renderCustomInput}
        />

        {props.enableOTPGeneration ? (
          <GenerateOTP
            border={"1px solid #006eff"}
            role="button"
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={props.initiateOTPGeneration}
          >
            <StartIcon style={{ width: "30px", height: "30px" }} />

            <ButtonText color={"#006eff"}>Generate OTP</ButtonText>
          </GenerateOTP>
        ) : (
          <GenerateOTP
            disabled
            border={"1px solid #959393"}
            background={"#f9f9f9"}
            opacity={"0.5"}
          >
            <StartIcon style={{ width: "30px", height: "30px" }} />

            <ButtonText color={"#959393"}>Generate OTP</ButtonText>
          </GenerateOTP>
        )}
      </FilterWrapper>
    </FilterContainer>
  );
};
Filters.propTypes = {
  filteredData: PropTypes.func,
  masterData: PropTypes.array,
  enableOTPGeneration: PropTypes.bool,
  initiateOTPGeneration: PropTypes.func,
  pickupDataByDate: PropTypes.func
};
