import styled from "styled-components";
import React from "react";
import { Error } from "./Error";
import PropTypes from "prop-types";
import { StyledFormInput } from "./FormInput";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: sticky;
  height: 48px;
  width: 305px;
  color: #3f3b3b;
  font-weight: 300;
`;

const Input = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledInput = styled(StyledFormInput)`
  width: 100%;
  border: 1px solid #959393;
  border-radius: 6px;
  padding-left: 52px;
  &:focus::placeholder {
    color: transparent;
  }
`;

const StyledIcon = styled.div`
  position: absolute;
  left: 16px;
  height: 20px;
  width: 20px;
`;

export const InputWithIcon = ({
  name,
  placeholder,
  value,
  type,
  Icon,
  handleChange,
  error,
  onBlur,
  onFocus
}) => {
  return (
    <Container>
      <Input>
        <StyledIcon as={Icon} />
        <StyledInput
          name={name}
          placeholder={placeholder}
          type={type}
          value={value}
          onInput={handleChange}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      </Input>
      {error && <Error message={error} />}
    </Container>
  );
};

InputWithIcon.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  Icon: PropTypes.elementType,
  handleChange: PropTypes.func,
  error: PropTypes.string,
  onBlur:PropTypes.func,
  onFocus:PropTypes.func
};
