import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { ReactComponent as Parent } from "../../static/child.svg";
import { Storage } from "aws-amplify";
import { Constants } from "../../utils/constants";
import { RiderDetails } from "./RiderDetails";
import { Avatar } from "../../components";

const VolunteerContainer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #ffffff;
  border-bottom: 1px solid #eaeaea;
`;
const VolunteerDetailsContainer = styled(motion.div)`
  display: flex;
  justify-content: space-evenly;
  margin: 0.5% 0 0.5% 0;
`;
const VDetails = styled(motion.div)`
  display: flex;
  font-family: Open Sans;
  align-items: center;
  justify-content: center;
  width: ${(p) => p.width};
`;

const VContainer = styled(motion.div)`
  display: flex;
  align-items: center;
  width: 100%;
  margin-left: 5%;
`;
const VNameContainer = styled(motion.div)`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 0 0 5px;
`;
const VText = styled(motion.span)`
  color: ${(p) => (p.color ? p.color : "#3f3b3b")};
  font-family: Open Sans;
  width: 100%;
  font-size: ${(p) => p.fontSize};
  font-weight: ${(p) => p.fontWeight};
  margin: ${(p) => p.margin};
`;
const RiderRow = styled(motion.div)`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

export const VolunteerDetails = (props) => {
  const [image, setImage] = useState(false);
  const [formattedPhoneNUmber, setFormattedPhoneNUmber] = useState("");

  useEffect(() => {
    async function setImages() {
      if (props.data) {
        if (props.data.photo_url) {
          let poImage = await Storage.get(props.data.photo_url, {
            level: "public",
            expires: 60,
            customPrefix: {
              public: Constants.profile_image_folders.profile_owners
            }
          });
          setImage(poImage);
        } else {
          setImage(false);
        }
      }
    }
    setImages();
    formatPhoneNumber(props.data.phone_number);
  }, [image, props.data.photo_url]);

  const formatPhoneNumber = (phone_number) => {
    let formattedPhoneNumber;
    if (phone_number) {
      let disIntegrate = phone_number.split("-");
      formattedPhoneNumber = "0" + disIntegrate[1];
    }
    setFormattedPhoneNUmber(formattedPhoneNumber);
  };

  return (
    <VolunteerContainer id="volunteer-main-container">
      <VolunteerDetailsContainer id="volunteer-details-container">
        <VDetails id="v-details" width={"25%"}>
          <VContainer id="profile-container" margin={"0 0 0 1%"}>
            {image ? (
              <Avatar size={"s"} img={image} />
            ) : (
              <Parent width="50px" height="50px" />
            )}

            <VNameContainer id="v-name-container">
              <VText fontSize={"12px"} fontWeight={"500"}>
                {props.data.first_name + " " + props.data.last_name}
              </VText>
              <VText fontSize={"12px"} fontWeight={"500"}>
                {formattedPhoneNUmber}
              </VText>
            </VNameContainer>
          </VContainer>
        </VDetails>
        <VDetails id="v-details" width={"64%"}>
          <RiderRow>
            {props.children_data &&
              props.children_data.map((child, index) => {
                return <RiderDetails key={index} childData={child} />;
              })}
          </RiderRow>
        </VDetails>
        <VDetails id="v-details" width={"10%"}>
          <VNameContainer id="v-name-container">
            <VText fontSize={"12px"} fontWeight={"500"}>
              {props.pickup_time}
            </VText>
          </VNameContainer>
        </VDetails>
        <VDetails id="v-details" width={"5%"}>
          <VNameContainer id="v-name-container">
            <VText fontSize={"12px"} fontWeight={"500"}>
              {props.otp ? props.otp : "******"}
            </VText>
          </VNameContainer>
        </VDetails>
      </VolunteerDetailsContainer>

      {/* <RiderContainer
            id="rider-container"
            layout
            initial={{ height: 0 }}
            animate={{ height: "fit-content" }}
            exit={{ opacity: 0 }}
          >
            <VText
              fontSize={"14px"}
              fontWeight={"600"}
              color={"#0caeff"}
              margin={"1% 0 0 1%"}
            >
              Children Details
            </VText>
           
          </RiderContainer>
        )} */}
    </VolunteerContainer>
  );
};
VolunteerDetails.propTypes = {
  transportation: PropTypes.string,
  children_data: PropTypes.array,
  data: PropTypes.object,
  pickup_time: PropTypes.string,
  run_id: PropTypes.number,
  otp: PropTypes.string
};
