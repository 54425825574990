import styled from "styled-components";
import React, { useEffect, useState } from "react";
import { Avatar } from "../../components";
import PropTypes from "prop-types";
import { ReactComponent as DoB } from "../../static/calendar.svg";
import { ReactComponent as Student } from "../../static/student.svg";
import { motion } from "framer-motion";
import ChildSVG from "../../static/child.svg";
import { Constants } from "../../utils/constants";
import { Storage } from "aws-amplify";

const ProfileContainer = styled(motion.div)`
  width: 200px;
  height: 200px;
  border-radius: 7.56px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
`;
const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileName = styled.span`
  color: #303133;
  font-family: Open Sans;
  font-size: 12.6px;
  font-weight: bold;
  margin-top: 5px;
`;
const DataContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const DataItemContainer = styled.div`
  display: flex;
  align-items: center;
`;
const Data = styled.span`
  color: #303133;
  font-family: Open Sans;
  font-size: 13px;
  padding-left: 4px;
`;
export const Child = ({ size, data }) => {
  const [childImage, setChildImage] = useState();
  useEffect(() => {
    async function setImages() {
      if (data) {
        if (data.child.photo_url) {
          Storage.configure({
            customPrefix: {
              public: Constants.profile_image_folders.children
            }
          });
          let childImage = await Storage.get(data.child.photo_url, {
            level: "public",
            expires: 60
          });
          setChildImage(childImage);
        }
      }
    }
    setImages();
  }, [data]);
  return (
    <ProfileContainer whileHover={{ scale: 1.1 }} whileTap={{ scale: 0.9 }}>
      <AvatarContainer>
        <Avatar size={size} img={childImage ? childImage : ChildSVG} />
        <ProfileName>
          {data.child.first_name} {data.child.last_name}
        </ProfileName>
      </AvatarContainer>
      <DataContainer>
        <DataItemContainer>
          <DoB />
          <Data>{data.child.dob}</Data>
        </DataItemContainer>
        <DataItemContainer>
          <Student />
          <Data>{data.child.key_stage_desc}</Data>
        </DataItemContainer>
      </DataContainer>
    </ProfileContainer>
  );
};

Child.propTypes = {
  size: PropTypes.string,
  img: PropTypes.string,
  data: PropTypes.object
};
