import { Auth } from "aws-amplify";
import { validateSession } from "./session-management";

class SkewlAuth {
  constructor() {
    this.user;
    this.userName;
    this.authenticated = false;
  }

  async signin(username, password) {
    this.user = await Auth.signIn(username, password);
    if (this.user.signInUserSession) {
      let session_id = this.user.signInUserSession.idToken.payload.session_id;
      let s_urn = this.user.signInUserSession.idToken.payload.s_urn;
      sessionStorage.setItem("session_id", session_id);
      sessionStorage.setItem("s_urn", s_urn);
    }

    return this.user;
  }

  async signout() {
    await Auth.signOut({ global: true });
    sessionStorage.removeItem("session_id");
    this.authenticated = false;
    this.user = {};
  }

  async isAuthenticated() {
    let session = sessionStorage.getItem("session_id");

    let doesSessionValid = false;
    if (session) {
      let result = await validateSession(session);
      if (result && result.session_validity) {
        doesSessionValid = true;
        if (result.session_extension) {
          sessionStorage.setItem("session_id", result.session_id);
        }
      }
    }
    if (!doesSessionValid) {
      sessionStorage.removeItem("session_id");
    }
    return doesSessionValid;
  }
  async completeNewPassword(newPassword) {
    await Auth.completeNewPassword(this.user, newPassword);
  }

  async forgotPassword(username) {
    this.userName = username;
    return await Auth.forgotPassword(username);
  }

  async confirmForgotPassword(code, password) {
    return this.userName
      ? await Auth.forgotPasswordSubmit(this.userName, code, password)
      : {};
  }

  async getToken() {
    let session = await Auth.currentSession();
    return session.getIdToken().getJwtToken();
  }

  async doesUserSuperAdmin() {
    let doesUserSuperAdmin = false;
    let session = await Auth.currentSession();
    if (session) {
      let userGroup = session.getIdToken().payload["cognito:groups"];
      if (userGroup.filter((group) => group === "super-admin").length > 0) {
        doesUserSuperAdmin = true;
      }
    }
    return doesUserSuperAdmin;
  }
  async getUserName() {
    let session = await Auth.currentSession();
    if (session) {
      return (
        session.getIdToken().payload["given_name"] +
        " " +
        session.getIdToken().payload["family_name"]
      );
    }
    return "";
  }
}

export default new SkewlAuth();
