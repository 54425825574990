import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

import PropTypes from "prop-types";
import styled from "styled-components";

const formatData = (data, filter) => {
  let formattedData = [];
  data &&
    data.map((d) => {
      let co2_data;
      if (filter && filter.type[0] === "transportation_mode") {
        co2_data = {
          date: d.date,
        };
        d.school_run.map((s) => {
          if (s.transportation_mode === filter.value[0]) {
            if (filter.type[1] && filter.type[1] == "session") {
              s.children.map((ses) => {
                if (ses.session === filter.value[1]) {
                  co2_data = {
                    date: d.date,
                    actual_co2: parseFloat(ses.actual_co2).toFixed(2),
                    estimated_co2: parseFloat(ses.estimated_co2).toFixed(2),
                    max_key:
                      parseFloat(ses.estimated_co2).toFixed(2) >
                      parseFloat(ses.actual_co2).toFixed(2)
                        ? "estimated_co2"
                        : "actual_co2",
                  };
                }
              });
            } else {
              co2_data = {
                date: d.date,
                actual_co2: parseFloat(s.total_actual_co2).toFixed(2),
                estimated_co2: parseFloat(s.total_estimated_co2).toFixed(2),
                max_key:
                  parseFloat(s.total_estimated_co2).toFixed(2) >
                  parseFloat(s.total_actual_co2).toFixed(2)
                    ? "estimated_co2"
                    : "actual_co2",
              };
            }
          }
        });
      } else {
        co2_data = {
          date: d.date,
          actual_co2: parseFloat(d.day_actual_co2).toFixed(2),
          estimated_co2: parseFloat(d.day_estimated_co2).toFixed(2),
          max_key:
            parseFloat(d.day_estimated_co2).toFixed(2) >
            parseFloat(d.day_actual_co2).toFixed(2)
              ? "estimated_co2"
              : "actual_co2",
        };
      }
      formattedData.push(co2_data);
    });
  return formattedData;
};

const StackBarChartContainer = styled.div`
  margin: 1% 0 0 9%;
  width: 100%;
  height: 100%;
  svg {
    overflow: visible;
  }
  .xAxisText {
    font-family: Open Sans;
    fill: #959393;
    font-size: 6px;
  }
  .yAxisText {
    font-family: Open Sans;
    fill: #959393;
    font-size: 6px;
    opacity: 1 !important;
  }
  .x-axis path {
    stroke: #959393;
    opacity: 0.5;
  }

  .y-axis line {
    stroke: #eaeaea;
    opacity: 1;
  }
`;

const Text = styled.text`
  fill: #959393;
  font-size: 10px;
  font-family: Open Sans;
`;
const ToolTipText = styled.text`
  fill: #ffffff;
  font-size: 10px;
  font-family: Open Sans;
`;

const colors = { estimated_co2: "#A1DAF9", actual_co2: "#0172D4" };
const keys = ["estimated_co2", "actual_co2"];

export const StackedBarChart = (props) => {
  const svgRef = useRef();
  let maxYValue = 0;
  const [toolTipPosistion, setToolTipPosition] = useState({
    x: 0,
    y: 0,
    opacity: 0,
    estimated_co2: 0,
    actual_co2: 0,
    date: "",
  });
  let formattedData;
  const parseDate = d3.timeParse("%Y-%m-%d");

  const getEstimatedCO2 = (date) => {
    let found = formattedData.find((co2) => co2.date === date);
    return found ? [found.estimated_co2, found.actual_co2] : [];
  };
  const handleMouseOver = (
    toolTipXPosition,
    toolTipYPosition,
    dataPoints,
    date
  ) => {
    setToolTipPosition({
      x: toolTipXPosition,
      y: toolTipYPosition,
      opacity: 1,
      estimated_co2: dataPoints[0],
      actual_co2: dataPoints[1],
      date: date,
    });
    d3.select("#id-tool-tip").moveToFront();
  };

  const handleMouseOut = () => {
    setToolTipPosition({
      x: 0,
      y: 0,
      opacity: 0,
      estimated_co2: 0,
      actual_co2: 0,
      date: "",
    });
  };

  d3.selection.prototype.moveToFront = function () {
    return this.each(function () {
      this.parentNode.appendChild(this);
    });
  };
  d3.selection.prototype.moveToBack = function () {
    return this.each(function () {
      var firstChild = this.parentNode.firstChild;
      if (firstChild) {
        this.parentNode.insertBefore(this, firstChild);
      }
    });
  };

  const adjustYValue1 = (sequence) => {
    let currentSequenceActualCO2 = +sequence.data.actual_co2;
    let currentSequenceEstimatedCO2 = +sequence.data.estimated_co2;
    let totalValue = currentSequenceActualCO2 + currentSequenceEstimatedCO2;
    let max_key_value = sequence.data.max_key;
    let yValue =
      sequence[1] >= totalValue ? +sequence.data[max_key_value] : sequence[1];
    return yValue;
  };

  // will be called initially and on every data change
  useEffect(() => {
    const svg = d3.select(svgRef.current);
    const stackGenerator = d3.stack().keys(keys).order(d3.stackOrderNone);
    formattedData = formatData(props.data, props.filter);

    const layers = stackGenerator(formattedData);
    /** Adjusting the layer value as actual CO2 and estimated emission can invariably change between them.
     * Meaning on good day, actual CO2 can be lesser than estimated. Also, actual CO2 can be more than than estimated  */
    layers.map((layer) => {
      layer.map((sequence) => {
        let y0_value =
          +sequence.data.actual_co2 < +sequence.data.estimated_co2
            ? +sequence.data.actual_co2
            : +sequence.data.estimated_co2;
        if (layer.key === sequence.data.max_key) {
          sequence[0] = y0_value;
          sequence[1] = sequence.data[sequence.data.max_key];
        } else {
          sequence[0] = 0;
          sequence[1] = y0_value;
        }
      });
    });

    /** Adjust the Y max value based on daily estimated and actual co2 */
    maxYValue = d3.max(layers, (layer) =>
      d3.max(layer, (sequence) => {
        if (
          sequence.data.max_key === "estimated_co2" &&
          layer.key === "estimated_co2"
        ) {
          return sequence.data.estimated_co2;
        } else {
          return sequence.data.actual_co2;
        }
      })
    );

    const extent = [0, maxYValue];
    const xScale = d3
      .scaleBand()
      .domain(formattedData.map((d) => parseDate(d.date)))
      .range([0, props.width])
      .padding(0.5);
    const xAxis = d3.axisBottom(xScale).tickSizeOuter(0).tickSizeInner(0);
    svg
      .select(" .x-axis")
      .attr("transform", `translate(0,${props.height})`)
      .call(
        xAxis,
        xAxis.tickFormat(d3.timeFormat("%d-%b")).tickValues(
          formattedData.map(function (d) {
            return parseDate(d.date);
          })
        )
      )
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("class", "xAxisText")
      .attr("dx", "-1em")
      .attr("dy", "0")
      .attr("transform", "rotate(-60)");

    const yScale = d3
      .scaleLinear()
      .domain(extent)
      .range([props.height, (props.height * 8) / 100]);
    const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);
    svg
      .select(" .y-axis")
      .call(yAxis.tickSize(-props.width))
      .call((g) => g.select(".domain").remove())
      .selectAll("text")
      .style("text-anchor", "end")
      .attr("class", "yAxisText")
      .attr("x", "-2")
      .attr("dy", "0");

    svg
      .selectAll(".layer")
      .data(layers)
      .join("g")
      .attr("class", "layer")
      .attr("fill", (layer) => colors[layer.key])
      .selectAll("rect")
      .data((layer) => layer)
      .join("rect")
      .attr("x", (sequence) => xScale(parseDate(sequence.data.date)))
      .attr("width", xScale.bandwidth())
      .attr("y", (sequence) => {
        return yScale(adjustYValue1(sequence));
      })
      .attr("height", 0)
      .on("mouseover", (d, i) => {
        let dataPoints = getEstimatedCO2(i.data.date);
        handleMouseOver(
          xScale(parseDate(i.data.date)),
          yScale(dataPoints[0] > dataPoints[1] ? dataPoints[0] : dataPoints[1]),
          dataPoints,
          i.data.date
        );
      })
      .on("mouseout", () => handleMouseOut())
      .transition()
      .ease(d3.easeCubic)
      .duration(1000)
      .attr("height", (sequence) => {
        return yScale(sequence[0]) - yScale(sequence[1]);
      });
  }, [props.data, props.filter]);

  return (
    <StackBarChartContainer>
      <svg
        ref={svgRef}
        viewBox={`0 0 ${props.width + props.left + props.right} 
                              ${props.height + props.top}`}>
        <g className="x-axis" />
        <g id="id-tool-tip">
          <rect
            id="tool-tip"
            x={toolTipPosistion.x - 40}
            y={toolTipPosistion.y - 40}
            opacity={toolTipPosistion.opacity}
            height={40}
            width={93}
            rx={3}
            ry={3}
            fill="#5d6f88"
          />
          <ToolTipText
            x={toolTipPosistion.x - 38}
            y={toolTipPosistion.y - 28}
            opacity={toolTipPosistion.opacity}>
            {"Date -" + toolTipPosistion.date}
          </ToolTipText>
          <ToolTipText
            x={toolTipPosistion.x - 38}
            y={toolTipPosistion.y - 16}
            opacity={toolTipPosistion.opacity}>
            {"Est. CO2 -" + toolTipPosistion.estimated_co2 + " kgs"}
          </ToolTipText>
          <ToolTipText
            x={toolTipPosistion.x - 38}
            y={toolTipPosistion.y - 5}
            opacity={toolTipPosistion.opacity}>
            {"Actual CO2 -" + toolTipPosistion.actual_co2 + " kgs"}
          </ToolTipText>
        </g>
        <g id="id-legend">
          <rect
            x={(props.width * 65) / 100}
            y={0}
            width={10}
            height={10}
            fill="#A1DAF9"
            rx="3"
            ry="3"
          />
          <Text x={(props.width * 68) / 100} y={8}>
            Estimated CO2
          </Text>
        </g>
        <g id="id-actual-co2">
          <rect
            x={(props.width * 87) / 100}
            y={0}
            width={10}
            height={10}
            fill="#0172D4"
            rx="3"
            ry="3"
          />
          <Text x={(props.width * 90) / 100} y={8}>
            Actual CO2
          </Text>
        </g>
        <Text
          transform={"rotate(-90)"}
          x={((0 - props.height) * 80) / 100}
          y={0 - props.left + 20}
          dy="1em">
          {"CO2 Emission in kgs"}
        </Text>
        <g className="y-axis" />
      </svg>
    </StackBarChartContainer>
  );
};

StackedBarChart.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  data: PropTypes.array,
  top: PropTypes.number,
  right: PropTypes.number,
  bottom: PropTypes.number,
  left: PropTypes.number,
  filter: PropTypes.object,
};
