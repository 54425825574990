import React from "react";
import "./App.css";
import Amplify from "aws-amplify";
import config from "./config/awsconfiguration";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { Registration } from "./pages/Registration";
import { Login } from "./pages/Login";
import { Main } from "./pages/Main";
import { ChangePassword } from "./pages/ChangePassword";
import { ResetPassword } from "./pages/ResetPassword";
import { ForgottenPassword } from "./pages/ForgottenPassword";
import { ProtectedRoute } from "./hoc/Routes/protected-route";
import { EmailConfirmation } from "./pages/EmailConfirmation";
import { EmailConfirmationError } from "./pages/EmailConfirmationError";
import { EmailAlreadyConfirmed } from "./pages/EmailAlreadyConfirmed";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      region: config.s3.REGION,
      bucket: config.s3.BUCKET,
    },
  },
});

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <ProtectedRoute path="/main" component={Main} />
          <ProtectedRoute exact path="/onboard" component={Registration} />
          <Route exact path="/resetpassword" component={ResetPassword} />
          <Route exact path="/forgotpassword" component={ForgottenPassword} />
          <Route
            exact
            path="/emailconfirmation"
            component={EmailConfirmation}
          />
          <Route
            exact
            path="/registrationerror"
            component={EmailConfirmationError}
          />
          <Route
            exact
            path="/registrationalreadyconfirmed"
            component={EmailAlreadyConfirmed}
          />
          <Route exact path="/changepassword" component={ChangePassword} />
          <Route path="/" component={Login} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
