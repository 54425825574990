import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "react-spinners-css";

export const Loader = (props) => {
  return props.showLoader && <Spinner color="#0caeff" size="1" />;
};

Loader.propTypes = {
  showLoader: PropTypes.bool
};
