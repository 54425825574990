import axios from "axios";
import SkewlAuth from "./skewl-auth";
import { Constants } from "../utils/constants";

const SKEWL_HOST = process.env.REACT_APP_API_URL;

export const getTripAnalyticalData = async () => {
  try {
    let uri = `v1/trip/analytics`;
    let session_id = sessionStorage.getItem("session_id");
    let apiToken = await SkewlAuth.getToken();
    const config = {
      method: "get",
      url: `${SKEWL_HOST}/${uri}/${encodeURIComponent(session_id)}`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
    };

    const res = await axios(config);
    if (res.status === Constants.http_status_codes.SUCCESS) {
      if (res.data.session_id) {
        sessionStorage.setItem("session_id", res.data.result.session_id);
      }
      return res.data;
    } else if (res.status === Constants.http_status_codes.NO_CONTENT) {
      return {};
    } else {
      throw new Error("unknown error");
    }
  } catch (error) {
    console.log(error);
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.UPDATE_SETTINGS_100;
    }
    return {
      error: errorCode,
    };
  }
};
