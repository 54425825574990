import React, { useState, useEffect } from "react";
import { Child, Modal, FilterOptions } from ".";
import styled from "styled-components";
import { motion } from "framer-motion";
import { BackDrop } from "../../components";
import { getProfileData } from "../../services";
import { verifyProfile } from "../../services";
import { Loader } from "../../components";
import { Constants } from "../../utils/constants";
import { useHistory } from "react-router-dom";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  height: fit-content;
  width: 80%;
  margin-top: 5%;
`;
const ContainerItem = styled(motion.div)`
  margin-top: 15px;
  margin-left: 15px;
  height: 200px;
`;

const containerItem = {
  initial: { opacity: 0 },
  animate: { opacity: 1, transition: 3 },
  exit: { opacity: 0, scale: 0, transition: 3 }
};

const ChildCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  height: fit-content;
  align-items: center;
`;

const NoDataFoundContainer = styled.div`
  color: #303133;
  font-family: Open Sans;
  font-size: 12.6px;
  width: 100%;
  font-weight: bold;
  margin-top: 20%;
  text-align: center;
`;

export const Verification = () => {
  let history = useHistory();

  const [showModal, setShowModal] = useState(false);
  const [showBackdrop, setShowBackdrop] = useState(true);
  const [childrenDetails, setChildrenDetails] = useState([]);
  const [filterDetails, setfilterDetails] = useState([]);
  const [selectedChild, setSelectedChild] = useState();
  const [showLoader, setShowLoader] = useState(true);
  const [error, setError] = useState();

  const updateFamily = async (child_id, isMatched, comments) => {
    setShowLoader(true);
    let profileVerification;
    if (isMatched) {
      profileVerification = await verifyProfile(child_id, "Matched", comments);
    } else {
      if (comments && comments != "") {
        profileVerification = await verifyProfile(
          child_id,
          "Un-Matched",
          comments
        );
      } else {
        setError(true);
        setShowLoader(false);
      }
    }
    if (profileVerification) {
      if (
        profileVerification.error === Constants.http_status_codes.UNAUTHROISED
      ) {
        history.push("/");
      } else {
        setShowModal(false);
        const data = await getProfileData();
        if (data.error === Constants.http_status_codes.UNAUTHROISED) {
          history.push("/");
        } else {
          setChildrenDetails(data.result);
          setfilterDetails(data.key_stages);
          setShowBackdrop(false);
          setShowLoader(false);
        }
      }
    }
  };

  useEffect(() => {
    async function getInitialData() {
      const data = await getProfileData();
      if (data.error === Constants.http_status_codes.UNAUTHROISED) {
        history.push("/");
      } else {
        
        setChildrenDetails(data.result);
        setfilterDetails(data.key_stages);
        setShowLoader(false);
        setShowBackdrop(false);
      }
    }
    getInitialData();
  }, []);

  const filteredData = async (year, section) => {
    var data = await getProfileData(year, section);
    if (data.error === Constants.http_status_codes.UNAUTHROISED) {
      history.push("/");
    } else {
      setChildrenDetails(data.result);
    }
  };

  const displayModal = (key) => {
    var data = childrenDetails.filter((i) => i.id === key)[0];
    setShowBackdrop(true);
    setShowModal(true);
    setSelectedChild(data);
  };
  return (
    <Container id="verification-container">
      <BackDrop showBackdrop={showBackdrop} setShowBackdrop={setShowBackdrop}>
        <Modal
          setShowModal={setShowModal}
          data={selectedChild}
          showModal={showModal}
          submitData={updateFamily}
          setShowBackdrop={setShowBackdrop}
          error={error}
          setError={setError}
        />
        <Loader showLoader={showLoader} />
      </BackDrop>
      <FilterOptions filteredData={filteredData} masterData={filterDetails} />
      {childrenDetails && childrenDetails.length > 0 ? (
        <ChildCardContainer id="children-container">
          {childrenDetails.map((family) => {
            return (
              <ContainerItem
                id="child-profile"
                onClick={() => displayModal(family.id)}
                variants={containerItem}
                initial="initial"
                animate="animate"
                exit="exit"
                key={family.id}
              >
                <Child size="xl" data={family} />
              </ContainerItem>
            );
          })}
        </ChildCardContainer>
      ) : (
        <NoDataFoundContainer>
          No children found based on your filter criteria
        </NoDataFoundContainer>
      )}
    </Container>
  );
};
