import styled from "styled-components";

const getImageVariants = {
  xs: [30, 30],
  s: [50, 50],
  m: [75, 75],
  l: [100, 100],
  xl: [125, 125]
};

const getAvatarMeasurement = (size, variant) => {
  return variant === 1 ? getImageVariants[size][0] : getImageVariants[size][1];
};

export const Avatar = styled.img.attrs((props) => ({ src: props.img }))`
  height: ${(props) => {
    return getAvatarMeasurement(props.size, 2);
  }}px;
  width: ${(props) => {
    return getAvatarMeasurement(props.size, 1);
  }}px;
  border-radius: 50%;
  background-color: ${(props) => props.backgroundColor};
  object-fit: scale-down;
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.27);
`;
