import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const StyledError = styled.div`
  color: #ff0000;
  font-size: 10px;
  text-align: center;
`;

export const Error = ({ message }) => {
  return <StyledError>{message}</StyledError>;
};

Error.propTypes = {
  message: PropTypes.string
};
