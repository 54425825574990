import React from "react";
import { Navigation } from "../../components/SideNavigationBar";
import { ReactComponent as DashBoard } from "../../static/dashboard.svg";
import { ReactComponent as School } from "../../static/school.svg";
import { ReactComponent as Settings } from "../../static/settings.svg";
import { ReactComponent as Signout } from "../../static/signout.svg";
import { ReactComponent as Generateotp } from "../../static/generate-otp.svg";
import { ReactComponent as Verify } from "../../static/verify.svg";
import { useLocation, useHistory } from "react-router-dom";
import styled from "styled-components";
import PropTypes from "prop-types";

const User = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: 100px;
`;
const UserText = styled.span`
  height: 21px;
  width: 169.8px;
  color: #959393;
  font-family: Open Sans;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 21px;
  padding-left: 29.06px;
  padding-right: 29.14px;
`;

const SideBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 5px;
`;
const FunctionalNavigation = styled.div`
  display: flex;
`;
export const SideBar = ({ setTitle, isUserSuperAdmin }) => {
  const history = useHistory();
  const location = useLocation();

  const getSchoolSubNav = () => {
    let subNav = [
      {
        title: "Classes",
        headerTitle: "Classes",
        itemId: "/main/school/classes"
      }
    ];
    return subNav;
  };

  const getSettingsSubNav = () => {
    let subNav = [
      {
        title: "Reset Password",
        headerTitle: "Reset Password",
        itemId: "/resetpassword"
      }
    ];
    return subNav;
  };
  return (
    <SideBarContainer>
      <FunctionalNavigation>
        <Navigation
          // you can use your own router's api to get pathname
          activeItemId={location.pathname}
          onSelect={({ itemId, title }) => {
            history.push(itemId);
            setTitle(title);
          }}
          items={
            !isUserSuperAdmin
              ? [
                  {
                    title: "Dashboard",
                    headerTitle: "Dashboard",
                    itemId: "/main/dashboard",
                    // you can use your own custom Icon component as well
                    // icon is optional
                    // eslint-disable-next-line react/display-name
                    elemBefore: () => <DashBoard />
                  },
                  {
                    title: "School",
                    headerTitle: "School Overview",
                    itemId: "/main/school",
                    // eslint-disable-next-line react/display-name
                    elemBefore: () => <School />,
                    subNav: getSchoolSubNav()
                  },
                  {
                    title: "Children Pickup Details",
                    headerTitle: "Evening Children Pickup Details",
                    itemId: "/main/trip",
                    // eslint-disable-next-line react/display-name
                    elemBefore: () => <Generateotp />
                  },
                  {
                    title: "Children Data Verification",
                    headerTitle: "Child Data Verification",
                    itemId: "/main/verification",
                    // eslint-disable-next-line react/display-name
                    elemBefore: () => <Verify />
                  }
                ]
              : [
                  // {
                  //   title: "Dashboard",
                  //   headerTitle: "Dashboard",
                  //   itemId: "/main/dashboard",
                  //   // you can use your own custom Icon component as well
                  //   // icon is optional
                  //   // eslint-disable-next-line react/display-name
                  //   elemBefore: () => <DashBoard />
                  // },
                  {
                    title: "Onboard",
                    headerTitle: "School Onboarding",
                    itemId: "/onboard"
                  }
                ]
          }
          setTitle={setTitle}
        ></Navigation>
      </FunctionalNavigation>
      <User>
        <UserText>USER</UserText>
        <Navigation
          // you can use your own router's api to get pathname
          activeItemId={location.pathname}
          onSelect={({ itemId }) => {
            history.push(itemId);
          }}
          items={[
            {
              title: "Settings",
              headerTitle: "User Overview",
              itemId: "/main/settings",
              // you can use your own custom Icon component as well
              // icon is optional
              // eslint-disable-next-line react/display-name
              elemBefore: () => <Settings />,
              subNav: getSettingsSubNav()
            },
            {
              title: "Sign Out",
              headerTitle: "Signing Out",
              itemId: "/main/signout",
              // eslint-disable-next-line react/display-name
              elemBefore: () => <Signout />
            }
          ]}
          setTitle={setTitle}
        />
      </User>
    </SideBarContainer>
  );
};

SideBar.propTypes = {
  setTitle: PropTypes.func,
  isUserSuperAdmin: PropTypes.bool
};
