import styled from "styled-components";
import React from "react";
import SkewlLogo from "../static/skewl-logo.svg";

const StyledFooter = styled.span`
  color: #959393;
  font-family: Open Sans;
  margin: 0;
  font-size: 12px;
  text-align: left;
  width: 89px;
`;

const StyledIcon = styled.img`
  height: 47px;
  width: 115.56px;
`;
const StyledFooterContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Footer = () => {
  return (
    <StyledFooterContainer>
      <StyledFooter>Powered by </StyledFooter>
      <StyledIcon src={SkewlLogo} />
    </StyledFooterContainer>
  );
};
