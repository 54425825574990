import React, { Fragment, useState } from "react";
import Background from "../static/login.png";
import Kid from "../static/kids.png";
import {
  HeaderLogo,
  Title,
  PrimaryButton,
  Footer,
  Error,
  Success,
  Loader,
  BackDrop
} from "../components";
import styled from "styled-components";
import { Password } from "../hoc/Login";
import { Passcode } from "../hoc/ResetPassword";
import SkewlAuth from "../services/skewl-auth";
import { Constants } from "../utils/constants";
import { useHistory } from "react-router-dom";

const Content = styled.div`
  background-image: url(${Background});
  background-size: cover;
  padding-top: 10%;
  padding-left: 15%;
  display: flex;
  justify-content: flex-start;
`;

const ChangePasswordText = styled.h1`
  height: 70px;
  width: 426px;
  color: #0099ff;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 70px;
  margin: 0 0 14px 0;
`;

const ChangePasswordWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ChangePasswordSection = styled.div`
  height: 525px;
  width: 445px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 25px;
`;

const StyledImage = styled.div`
  padding-left: 25%;
`;

const FooterDivider = styled.div`
  height: 1px;
  width: 428px;
  background-color: #979797;
  opacity: 0.5;
  margin-top: 83px;
`;

export const ResetPassword = () => {
  document.title = "Skewl | Reset Password";

  let history = useHistory();
  const [passcode, setPasscode] = useState({
    value: "",
    error: ""
  });
  const [newpassword, setNewPassword] = useState({
    value: "",
    error: ""
  });
  const [confirmpassword, setConfirmPassword] = useState({
    value: "",
    error: ""
  });
  const [formSubmissionError, setFormSubmissionError] = useState("");
  const [message, setMessage] = useState(Constants.messages.RESET_PASSWORD);
   const[showLoader,setShowLoader] = useState(false);

  const handlePasscode = (value) => {
    setPasscode({
      value: value,
      error: ""
    });
  };

  const handleNewPassword = (value) => {
    setNewPassword({
      value: value,
      error: ""
    });
  };

  const handleConfirmPassword = (e) => {
    setConfirmPassword({
      value: e,
      error: ""
    });
  };

  const validateValues = () => {
    let areValid = true;
    if (passcode.value === "") {
      setPasscode({
        value: passcode.value,
        error: "Please enter the passcode"
      });
      areValid = false;
    }
    if (newpassword.value === "") {
      setNewPassword({
        value: newpassword.value,
        error: "Please enter the new password"
      });
      areValid = false;
    }
    if (confirmpassword.value === "") {
      setConfirmPassword({
        value: confirmpassword.value,
        error: "Please re-enter the password"
      });
      areValid = false;
    }
    if (
      newpassword.value != "" &&
      confirmpassword.value != "" &&
      newpassword.value != confirmpassword.value
    ) {
      setConfirmPassword({
        value: confirmpassword.value,
        error: "Please check your confirm password should match new password"
      });
      areValid = false;
    }
    return areValid;
  };

  const resetErrors = () => {
    passcode.error = "";
    newpassword.error = "";
    confirmpassword.error = "";
    setMessage("");
    setFormSubmissionError("");
  };

  const handleResetPassword = async (event) => {
    resetErrors();
    if (validateValues()) {
      try
      {
        setShowLoader(true);
        await SkewlAuth.confirmForgotPassword(
          passcode.value,
          newpassword.value
        );
        setShowLoader(false);
        history.push(
           "/",
          {
            hasPasswordReset: true
          }
        );
      } catch (error)
      {
        setShowLoader(false);
        if (error.code === "CodeMismatchException") {
          setPasscode({
            value: passcode.value,
            error: "Please check your passcode"
          });
        } else if (error.code === "ExpiredCodeException") {
          history.push("/");
        } else if (error.code === "InternalErrorException") {
          setFormSubmissionError("Please re-try after sometime");
        } else if (error.code === "InvalidLambdaResponseException") {
          setFormSubmissionError("Please re-try after sometime");
        } else if (error.code === "InvalidParameterException") {
          setFormSubmissionError("Please re-try after sometime");
        } else if (error.code === "InvalidPasswordException") {
          setNewPassword({
            value: newpassword.value,
            error:
              "Please check your password format, accepted format is alphanumeric with minimum 8 characters combination of upper and lowercase "
          });
        } else if (error.code === "LimitExceededException") {
          setFormSubmissionError("Please re-try after sometime");
        } else if (error.code === "NotAuthorizedException") {
          history.push("/");
        } else if (error.code === "UserNotFoundException") {
          history.push("/");
        } else if (error.code === "TooManyFailedAttemptsException") {
          setFormSubmissionError(
            "You have exceeded the number of attempst, Please re-try after sometime"
          );
        } else if (error.code === "TooManyRequestsException") {
          setFormSubmissionError(
            "You have exceeded the number of attempst, Please re-try after sometime"
          );
        }
        console.log("error signing in", error);
      }

      event.preventDefault();
    }
  };

  return (
    <Fragment>
      <HeaderLogo />
      <Content>
         <BackDrop showBackdrop={showLoader} setShowBackdrop={setShowLoader}>
        <Loader showLoader={showLoader}/>
        </BackDrop>
        <ChangePasswordWrapper>
          <ChangePasswordText>Welcome Back!</ChangePasswordText>
          <ChangePasswordSection>
            <Title title="Change Password" />
            <Success message={message} />
            <Error message={formSubmissionError} />
            <Passcode
              value={passcode.value}
              handleOnInput={handlePasscode}
              error={passcode.error}
            />
            <Password
              placeholder="New Password"
              value={newpassword.value}
              handleOnInput={handleNewPassword}
              error={newpassword.error}
            />
            <Password
              placeholder="Confirm New Password"
              value={confirmpassword.value}
              handleOnInput={handleConfirmPassword}
              error={confirmpassword.error}
            />
            <PrimaryButton
              type="submit"
              value="Submit"
              handleClick={handleResetPassword}
              width={"305px"}
              height={"48px"}
              fontSize={"18px"}
              borderRadius={"6px"}
            />
            <FooterDivider />
            <Footer />
          </ChangePasswordSection>
        </ChangePasswordWrapper>
        <StyledImage>
          <img src={Kid} />
        </StyledImage>
      </Content>
    </Fragment>
  );
};
