import React, { Fragment, useState, useEffect } from "react";
import Background from "../static/login.png";
import Kid from "../static/kids.png";
import {
  HeaderLogo,
  Title,
  PrimaryButton,
  Footer,
  Error,
  Success,
  Loader,
  BackDrop
} from "../components";
import styled from "styled-components";
import { Username, Password } from "../hoc/Login";
import SkewlAuth from "../services/skewl-auth";
import { Constants } from "../utils/constants";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

const Content = styled.div`
  background-image: url(${Background});
  background-size: cover;
  padding-top: 10%;
  padding-left: 15%;
  display: flex;
  justify-content: flex-start;
`;

const WelcomeBackText = styled.h1`
  height: 70px;
  width: 426px;
  color: #0099ff;
  font-size: 60px;
  font-weight: bold;
  letter-spacing: -0.7px;
  line-height: 70px;
  margin: 0 0 14px 0;
`;

const LoginWrapper = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginSection = styled.div`
  height: 525px;
  width: 445px;
  box-sizing: border-box;
  border-radius: 12px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px 0 rgba(0, 0, 0, 0.32);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 25px;
`;

const ForgotPassword = styled.div`
  color: #959393;
  font-family: Open Sans;
  font-size: 14px;
  cursor: pointer;
`;

const StyledImage = styled.div`
  padding-left: 25%;
`;

const FooterDivider = styled.div`
  height: 1px;
  width: 428px;
  background-color: #979797;
  opacity: 0.5;
  margin-top: 83px;
`;

export const Login = (props) => {
  document.title = "Skewl | Login";
  let history = useHistory();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const[showLoader,setShowLoader] = useState(false);
  useEffect(() => {
    if (props.location.state) {
      props.location.state.sessionExpired
        ? setError(Constants.error.SESSION_EXPIRED)
        : props.location.state.hasPasswordReset
        ? setMessage(Constants.messages.RESET_PASSWORD_SUCCESSFUL)
        : "";
    }
  });
  const handleUsernameLogin = (value) => {
    error != "" ? setError("") : "";
    setUsername(value);
  };

  const handlePassword = (e) => {
    error != "" ? setError("") : "";
    setPassword(e);
  };

  const handleForgotPassword = (event) => {
    history.push("/forgotpassword");
    event.preventDefault();
  };

  const handleLogin = async (event) => {
    try {
      setShowLoader(true);
      let user = await SkewlAuth.signin(username, password);
      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        setShowLoader(false);
        history.push("/changepassword");
      } else
      {
        setShowLoader(false);
        if (await SkewlAuth.doesUserSuperAdmin())
        {
          history.push("/onboard");
        }
        else
        {
          history.push("/main/dashboard");
        }
        
        
      }
    } catch (error) {
      if (error.code === "PasswordResetRequiredException") {
        setShowLoader(false);
        await SkewlAuth.forgotPassword(username);
        history.push("/resetpassword");
      }
      if (error.code === "NotAuthorizedException") {
        setShowLoader(false);
        setError(Constants.error.LOGIN_ERROR);
      }
    }
    event.preventDefault();
  };

  return (
    <Fragment>
      <HeaderLogo />
      <Content>
      <BackDrop showBackdrop={showLoader} setShowBackdrop={setShowLoader}>
        <Loader showLoader={showLoader}/>
        </BackDrop>
        <LoginWrapper>
          <WelcomeBackText>Welcome Back!</WelcomeBackText>
          <form onSubmit={handleLogin}>
          <LoginSection>
            <Title title="Login" />
            
            <Success message={message} />
            <Error message={error} />
            <Username value={username} handleOnInput={handleUsernameLogin} />
            <Password
              placeholder="Password"
              value={password}
              handleOnInput={handlePassword}
            />
            <PrimaryButton
              type="submit"
              value="Submit"
              handleClick={handleLogin}
              width={"305px"}
              height={"48px"}
              fontSize={"18px"}
              borderRadius={"6px"}
            />
            {/*<StyledSomething type="submit" value="Login" data-test-id="login" />*/}
            <ForgotPassword onClick={handleForgotPassword}>
              Forgot password ?
            </ForgotPassword>
            <FooterDivider />
            <Footer />
            
          </LoginSection>
          </form>
        </LoginWrapper>
        <StyledImage>
          <img src={Kid} />
        </StyledImage>
      </Content>
    </Fragment>
  );
};

Login.propTypes = {
  location: PropTypes.object
};
