import styled from "styled-components";
import React from "react";
import { useRecoilValue } from "recoil";
import { activeLabelSelector } from "./TabState";
import PropTypes from "prop-types";

const TabName = styled.span`
  cursor: pointer;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: ${(p) => p.color};
  border-radius: 16px;
  text-align: center;
  display: grid;
  z-index: 1;
  align-items: center;
  transition: background 50s ease-out;
  transition: color 1s ease-out;

  &:hover {
    font-weight: 500 !important;
  }
`;

/**
 * It is a component to render Tab's label. The tab shows darker color if it is active.
 * @param {Object} props The component props
 */
export const Tab = (props) => {
  const activeLabel = useRecoilValue(activeLabelSelector);
  const { label } = props;
  return (
    <TabName color={label === activeLabel ? "#FFFFFF" : "#3f3b3b"}>
      {label}
    </TabName>
  );
};
Tab.propTypes = {
  label: PropTypes.string
};
