import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { motion } from "framer-motion";

const Button = styled(motion.input)`
  border-radius: ${(props) => props.borderRadius};
  border: none;
  background-color: #0caeff;
  color: #ffffff;
  font-family: Open Sans;
  font-size: ${(props) => props.fontSize};
  font-weight: bold;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
  letter-spacing: 0;
  text-align: center;
`;

export const PrimaryButton = (props) => {
  return (
    <Button
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      type={props.type}
      value={props.value}
      onClick={props.handleClick}
      fontSize={props.fontSize}
      borderRadius={props.borderRadius}
      height={props.height}
      width={props.width}
    />
  );
};

PrimaryButton.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  handleClick: PropTypes.func,
  width: PropTypes.string,
  height: PropTypes.string,
  borderRadius: PropTypes.string,
  fontSize: PropTypes.string
};
