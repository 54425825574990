import {
  Map,
  PrimaryButton,
  Avatar,
  BackgroundImage,
  BackDrop,
  Loader,
  TimePicker,
  Tab,
  TabHolder,
  Input,
  ConfirmationModal,
  Error,
  Success
} from "../../../components";
import Switch from "react-switch";
import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { Marker } from "react-map-gl";
import { ReactComponent as SchoolSVG } from "../../../static/school-location.svg";
import {
  getSchoolDetails,
  updateSchoolSettings,
  updateSchoolAddress,
  updateSchoolDetails
} from "../../../services";
import { useHistory } from "react-router-dom";
import { Constants } from "../../../utils/constants";
import { RecoilRoot } from "recoil";
import { ColorPicker, createColor } from "material-ui-color";

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 7%;
  height: 100vh;
`;
const ContentContainer = styled.div`
  display: flex;
  width: 80%;
  max-width: 80%;
  overflow: hidden;
  margin-top: 10%;
  height: 100vh;
  position: absolute;
`;

const SchoolContainer = styled.div`
  display: flex;
  width: 38%;
  position: absolute;
  height: 82%;
  box-shadow: 0 0 1px 0 rgba(132, 214, 255, 0.04),
    0 2px 4px 0 rgba(12, 174, 255, 0.16);
  margin-left: 5%;
  flex-direction: column;
`;
const SchoolNameContainer = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
`;
const SchoolNameHolder = styled.div`
  width: 100%;
  height: 100%;
  background-color: #0caeff;
  display: flex;
  align-items: center;
`;
const SchoolDetailsContainer = styled.div`
  height: fit-content;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
`;
const SchoolDetails = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  background-color: #0172d4;
  margin-top: 4px;
`;
const SchoolLogo = styled.div`
  padding: 5px 0 0 5px;
  width: 75px;
`;

const SchoolDetailsText = styled.span`
  height: 28px;
  color: ${(props) => props.fontColor};
  font-family: Open Sans Bold;
  font-size: ${(props) => props.fontSize};
  letter-spacing: 0;
  line-height: 28px;
  padding: ${(props) => props.padding};
  font-weight: ${(props) => props.fontWeight};
  text-align: ${(props) => props.textAlign};
  width: ${(props) => props.width};
`;
const SchoolDetailsHeadingContainer = styled.div`
  height: 28px;
  display: flex;
  font-family: Open Sans;
  font-size: 16px;
  margin: 0 0 0 10px;
  font-weight: 700;
  color: ${(props) => props.color};
  align: left;
  padding: 5px 0 0 0;
`;

const SchoolDetailsSubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const TabContainer = styled.div`
  width: 53%;
  position: absolute;
  height: 82%;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(132, 214, 255, 0.04),
    0 2px 4px 0 rgba(12, 174, 255, 0.16);
  margin-left: 45%;
`;

const TabWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.height};
  margin: 35px 0 0 0;
  flex-direction: column;
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 30px 0 0 0;
  flex-wrap: wrap;
  justify-content: space-around;
`;
const RowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
`;

const FieldSubHeading = styled.span`
  color: #959393;
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0 10px 15px;
  font-family: Open Sans;
  width: 100%;
  text-align: left;
`;

const SettingsFields = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
`;

const SettingsFormElement = styled.div`
  width: 50%;
  position: relative;
  margin-left: 2px;
  height: 48px;
`;

const SettingsLabel = styled.div`
  font-family: Open Sans;
  font-size: 14px;
  color: #3f3b3b;
  width: 250px;
  height: 48px;
`;

const ColorPickerContainer = styled.div`
  max-width: 45%;
  flex-wrap: wrap;
  display: flex;
  margin: 0 2px 14px 0;
  flex-basis: 100%;
  flex-grow: 1;
  font-family: Open Sans !important;
  font-size: 13px !important;
  position: sticky;
  height: 48px;
`;

const SchoolLogoBackgroundColorLabel = styled.span`
  color: #959393;
  font-size: 14px;
  font-family: Open Sans;
  width: 100%;
  text-align: left;
`;

const ModalParagraph = styled.span`
  font-family: Open Sans;
  font-size: 12px;
  color: #000000;
`;
const ModalConfirmation = styled.div`
  margin-top: 5px;
`;

const ModalDialogWrapper = styled.div`
  margin: 5px;
  padding: 0 10px;
`;

const SchoolAddressMap = styled.div`
  width: 250px;
  height: fit-content;
  background-color: #ffffff;
  border-radius: 16px;
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
  margin: 10px 0 0 10px;
`;
const Address = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 0 15px;
`;
const SpanText = styled.span`
  font-family: Open Sans;
  font-size: 12px;
`;

export const SchoolOverview = () => {
  document.title = "Skewl | School Overview";
  let history = useHistory();
  const [schoolLatitude, setSchoolLatitude] = useState({
    value: 51.509865,
    error: ""
  });
  const [schoolLongitude, setSchoolLongitude] = useState({
    value: -0.118092,
    error: ""
  });

  const [schoolDetails, setSchoolDetails] = useState();
  const [schoolName, setSchoolName] = useState();
  const [academyTrust, setAcademyTrust] = useState();
  const [websiteURL, setWebsiteURL] = useState({ value: "", error: "" });
  const [capacity, setCapacity] = useState({ value: "", error: "" });
  const [schoolType, setSchoolType] = useState();
  const [establishmentType, setEstablishmentType] = useState();
  const [telephone, setTelephone] = useState({ value: "", error: "" });
  const [settingsId, setSettingsId] = useState();
  const [profileApprovalEnabled, setProfileApprovalEnabled] = useState(false);
  const [otpBasedDismissal, setOtpBasedDismissal] = useState(false);
  const [dismissalTime, setDismissalTime] = useState("900");
  const [eveningScheduleCutOffRange, setEveningScheduleCutOffRange] =
    useState("870");
  const [logoUrl, setLogoUrl] = useState();
  const [schoolLogoBackgroundColor, setSchoolLogoBackgroundColor] = useState(
    createColor("#000")
  );
  const [addressLine1, setAddressLine1] = useState({
    value: "",
    error: ""
  });
  const [addressLine2, setAddressLine2] = useState();
  const [addressLine3, setAddressLine3] = useState();
  const [addressLine4, setAddressLine4] = useState();
  const [emailAddress, setEmailAddress] = useState({ value: "", error: "" });
  const [county, setCounty] = useState({ value: "", error: "" });
  const [country, setCountry] = useState({ value: "", error: "" });
  const [city, setCity] = useState({ value: "", error: "" });
  const [postCode, setPostCode] = useState({ value: "", error: "" });
  const [showModal, setShowModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState();
  const [showBackdrop, setShowBackdrop] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [updateDataType, setUpdateDataType] = useState();
  const [error, setError] = useState();
  const [success, setSuccess] = useState();

  useEffect(() => {
    async function getSchoolInfo() {
      let data = await getSchoolDetails();
      if (data && data.error === Constants.http_status_codes.UNAUTHROISED) {
        history.push("/");
      } else {
        console.log(JSON.stringify(data));
        setSchoolDetails(data);

        setSchoolName(data.schoolName);
        setAcademyTrust(data.academyTrust);
        setWebsiteURL({ value: data.websiteURL, error: "" });
        setCapacity({ value: +data.capacity, error: "" });
        setSchoolType(data.schoolType);
        setEstablishmentType(data.establishmentType);
        setTelephone({ value: data.telephone, error: "" });
        setSettingsId(data.schoolSettings.id);
        setProfileApprovalEnabled(data.schoolSettings.profile_approval_enabled);
        setOtpBasedDismissal(data.schoolSettings.otp_based_dismissal);
        setDismissalTime(data.schoolSettings.dismissal_time);
        setEmailAddress({ value: data.emailAddress, error: "" });
        setEveningScheduleCutOffRange(
          data.schoolSettings.evening_schedule_cut_off_range
        );
        setLogoUrl(data.schoolSettings.logo_url);
        setAddressLine1({
          value:
            data.schoolAddress.address_line_1 &&
            data.schoolAddress.address_line_1 != null
              ? data.schoolAddress.address_line_1
              : "",
          error: ""
        });
        setAddressLine2(
          data.schoolAddress.address_line_2 &&
            data.schoolAddress.address_line_2 != null
            ? data.schoolAddress.address_line_2
            : ""
        );
        setAddressLine3(
          data.schoolAddress.address_line_3 &&
            data.schoolAddress.address_line_3 != null
            ? data.schoolAddress.address_line_3
            : ""
        );
        setAddressLine4(
          data.schoolAddress.address_line_4 &&
            data.schoolAddress.address_line_4 != null
            ? data.schoolAddress.address_line_4
            : ""
        );
        setCounty({ value: data.schoolAddress.county, error: "" });
        setCountry({ value: data.schoolAddress.country, error: "" });
        setCity({ value: data.schoolAddress.city, error: "" });
        setPostCode({ value: data.schoolAddress.post_code, error: "" });
        setSchoolLatitude({ value: data.locationCoordinates[0], error: "" });
        setSchoolLongitude({ value: data.locationCoordinates[1], error: "" });
        setSchoolLogoBackgroundColor(
          createColor(data.schoolSettings.logo_background_colour)
        );
        setError("");
        setSuccess("");
      }
    }
    getSchoolInfo();
  }, []);

  const handleOnchangeBackgroundColor = (value) => {
    setSchoolLogoBackgroundColor(value);
  };

  const updateSettings = async (event) => {
    setShowModal(false);
    setShowLoader(true);
    let result = await updateSchoolSettings(
      otpBasedDismissal,
      dismissalTime,
      eveningScheduleCutOffRange,
      settingsId,
      logoUrl,
      schoolLogoBackgroundColor.css.backgroundColor
    );
    if (result.error != "") {
      if (result.error === Constants.http_status_codes.UNAUTHROISED) {
        history.push("/");
      } else {
        setError(result.error);
        setSuccess("");
      }
    } else {
      if (result.id) {
        setSettingsId(result.id);
      }
      schoolDetails.schoolSettings.id = result.id;
      schoolDetails.schoolSettings.otp_based_dismissal = otpBasedDismissal;
      schoolDetails.schoolSettings.dismissal_time = dismissalTime;
      schoolDetails.schoolSettings.evening_schedule_cut_off_range =
        eveningScheduleCutOffRange;
      schoolDetails.schoolSettings.logo_url = logoUrl;
      schoolDetails.schoolSettings.logo_background_colour =
        schoolLogoBackgroundColor.css.backgroundColor;
      setError(result.error);
      setSuccess(Constants.messages.UPDATE_SETTINGS_SUCCESS);
    }

    setShowBackdrop(false);
    setShowLoader(false);
    event.preventDefault();
  };

  const editSchool = (message, dataType) => {
    setConfirmationMessage(message);
    setUpdateDataType(dataType);
    setShowModal(true);
    setShowBackdrop(true);
  };

  const updateDetails = async (event) => {
    setShowModal(false);
    setShowLoader(true);
    setShowBackdrop(true);
    if (
      (capacity.error && capacity.error != "") ||
      (websiteURL.error && websiteURL.error != "")
    ) {
      setError("Please enter the values in all required fields");
      setShowLoader(false);
      setShowBackdrop(false);
    } else {
      let result = await updateSchoolDetails(capacity.value, websiteURL.value);
      if (result.error != "") {
        if (result.error === Constants.http_status_codes.UNAUTHROISED) {
          history.push("/");
        } else {
          setError(result.error);
          setSuccess("");
        }
      } else {
        setError(result.error);
        setSuccess(Constants.messages.UPDATE_SCHOOL_DETAILS);
      }
      setShowLoader(false);
      setShowBackdrop(false);
    }
    event.preventDefault();
  };
  const updateAddressDetails = async (event) => {
    setShowModal(false);
    setShowLoader(true);
    setShowBackdrop(true);
    if (
      (addressLine1.error && addressLine1.error != "") ||
      (city.error && city.error != "") ||
      (county.error && county.error != "") ||
      (country.error && country.error != "") ||
      (schoolLatitude.error && schoolLatitude.error != "") ||
      (schoolLongitude.error && schoolLongitude.error != "") ||
      (telephone.error && telephone.error != null) ||
      (emailAddress.error && emailAddress.error != null)
    ) {
      setError("Please enter the values in all required fields");
      setShowLoader(false);
      setShowBackdrop(false);
    } else {
      let address = {};
      let contact_details = {
        Telephone: telephone.value,
        Email: emailAddress.value
      };
      let location_coordinates = [schoolLatitude.value, schoolLongitude.value];
      address["address_line_1"] = addressLine1.value;
      address["address_line_2"] = addressLine2 ? addressLine2.value : "";
      address["address_line_3"] = addressLine3 ? addressLine3.value : "";
      address["address_line_4"] = addressLine4 ? addressLine4.value : "";
      address["city"] = city.value;
      address["county"] = county.value;
      address["country"] = country.value;
      address["post_code"] = postCode.value;
      address["contact_details"] = contact_details;
      address["location_coordinates"] = location_coordinates;
      let result = await updateSchoolAddress(address);
      if (result.error != "") {
        if (result.error === Constants.http_status_codes.UNAUTHROISED) {
          history.push("/");
        } else {
          setError(result.error);
          setSuccess("");
        }
      } else {
        setError(result.error);
        setSuccess(Constants.messages.UPDATE_ADDRESS_SUCCESS);
      }
      setShowLoader(false);
      setShowBackdrop(false);
    }

    event.preventDefault();
  };
  const cancelModal = () => {
    setShowBackdrop(false);
    setShowModal(false);
  };

  const handleOnchangeWebsiteAddress = (event) => {
    setWebsiteURL({
      value: event.target.value,
      error: !event.target.value
        ? "Please enter the school website address"
        : ""
    });
  };

  const handleOnchangeAddress = (event) => {
    switch (event.target.name) {
      case "addressLine1":
        setAddressLine1({
          value: event.target.value,
          error: !event.target.value ? "Please enter address line1" : ""
        });
        break;
      case "addressLine2":
        setAddressLine2(event.target.value);
        break;
      case "addressLine3":
        setAddressLine3(event.target.value);
        break;
      case "addressLine4":
        setAddressLine4(event.target.value);
        break;
      case "postCode":
        setPostCode({
          value: event.target.value,
          error: !event.target.value ? "Please enter the post code" : ""
        });
        break;
      case "city":
        setCity({
          value: event.target.value,
          error: !event.target.value ? "Please enter the city" : ""
        });
        break;
      case "county":
        setCounty({
          value: event.target.value,
          error: !event.target.value ? "Please enter the county" : ""
        });
        break;
      case "country":
        setCountry({
          value: event.target.value,
          error: !event.target.value ? "Please enter the country" : ""
        });
        break;
      case "longitude":
        setSchoolLongitude({
          value: event.target.value && parseFloat(event.target.value),
          error: !event.target.value
            ? "Please enter the school location longitude"
            : ""
        });
        break;
      case "latitude":
        setSchoolLatitude({
          value: event.target.value && parseFloat(event.target.value),
          error: !event.target.value
            ? "Please enter the school location latitude"
            : ""
        });
        break;
    }
  };

  const handleOnchangeLogoURL = (event) => {
    setLogoUrl(event.target.value);
  };

  const handleOnchangeTelephone = (event) => {
    setTelephone({
      value: event.target.value,
      error: !event.target.value ? "Please enter the school contact number" : ""
    });
  };

  const handleOnchangeCapacity = (event) => {
    setCapacity({
      value: event.target.value,
      error: !event.target.value
        ? "Please enter number of pupils in the school"
        : ""
    });
  };
  const onDissimissalTimeChange = (event) => {
    setDismissalTime(event.target.value);
    event.target.blur();
  };
  const onLastScheduleTimeChange = (event) => {
    setEveningScheduleCutOffRange(event.target.value);
    event.target.blur();
  };
  const handleOTPBasedDismissal = (checked) => {
    setOtpBasedDismissal(checked);
  };

  const handleOnchangeEmail = (event) => {
    setEmailAddress({
      value: event.target.value,
      error: !event.target.value ? "Please enter school email address" : ""
    });
  };
  /** Methos to reset values when moving between the tabs */
  const resetValues = (previosuActiveLabel) => {
    switch (previosuActiveLabel) {
      case "School Details":
        setCapacity({ value: schoolDetails.capacity, error: "" });
        setWebsiteURL({ value: schoolDetails.websiteURL, error: "" });
        break;
      case "Address Details":
        setAddressLine1({
          value: schoolDetails.schoolAddress.address_line_1,
          error: ""
        });
        setAddressLine2(schoolDetails.schoolAddress.address_line_2);
        setAddressLine3(schoolDetails.schoolAddress.address_line_3);
        setAddressLine4(schoolDetails.schoolAddress.address_line_4);
        setCounty({ value: schoolDetails.schoolAddress.county, error: "" });
        setCountry({ value: schoolDetails.schoolAddress.country, error: "" });
        setCity({ value: schoolDetails.schoolAddress.city, error: "" });
        setPostCode({
          value: schoolDetails.schoolAddress.post_code,
          error: ""
        });
        setTelephone({
          value: schoolDetails.telephone,
          error: ""
        });
        setEmailAddress({
          value: schoolDetails.emailAddress,
          error: ""
        });
        setSchoolLatitude({
          value: schoolDetails.locationCoordinates[0],
          error: ""
        });
        setSchoolLongitude({
          value: schoolDetails.locationCoordinates[1],
          error: ""
        });
        break;

      case "Settings":
        setProfileApprovalEnabled(
          schoolDetails.schoolSettings.profile_approval_enabled
        );
        setOtpBasedDismissal(schoolDetails.schoolSettings.otp_based_dismissal);
        setDismissalTime(schoolDetails.schoolSettings.dismissal_time);
        setEveningScheduleCutOffRange(
          schoolDetails.schoolSettings.evening_schedule_cut_off_range
        );
        setLogoUrl(schoolDetails.schoolSettings.logo_url);
        setSchoolLogoBackgroundColor(
          createColor(schoolDetails.schoolSettings.logo_background_colour)
        );
        break;
    }
    setError("");
    setSuccess("");
  };

  return (
    <RecoilRoot>
      <Container>
        <BackDrop showBackdrop={showBackdrop} setShowBackdrop={setShowBackdrop}>
          <ConfirmationModal
            showModal={showModal}
            cancel={cancelModal}
            confirm={
              updateDataType === "SETTINGS"
                ? updateSettings
                : updateDataType === "SCHOOL_DETAILS"
                ? updateDetails
                : updateAddressDetails
            }
          >
            <ModalDialogWrapper>
              <ModalParagraph>{confirmationMessage}</ModalParagraph>
              <ModalConfirmation>
                <ModalParagraph>
                  {"Are you sure, you want to proceed ?"}
                </ModalParagraph>
              </ModalConfirmation>
            </ModalDialogWrapper>
          </ConfirmationModal>
          <Loader showLoader={showLoader} />
        </BackDrop>
        <BackgroundImage width={"80%"} position={"absolute"} />
        <ContentContainer id="content-container">
          {schoolDetails && (
            <Fragment>
              <SchoolContainer id="school-container">
                <SchoolNameContainer id="school-name-container">
                  <SchoolNameHolder id="school-name-holder">
                    <SchoolLogo id="school-logo">
                      <Avatar
                        size={"m"}
                        img={logoUrl}
                        backgroundColor={
                          schoolLogoBackgroundColor &&
                          schoolLogoBackgroundColor.css &&
                          schoolLogoBackgroundColor.css.backgroundColor
                        }
                      />
                    </SchoolLogo>
                    <SchoolDetailsText
                      fontSize={"25px"}
                      padding={"0 0 0 5px"}
                      fontWeight={700}
                      fontColor={"#FFFFFF"}
                      textAlign={"left"}
                    >
                      {schoolName}
                    </SchoolDetailsText>
                  </SchoolNameHolder>
                </SchoolNameContainer>
                <SchoolDetailsContainer>
                  <SchoolDetails height={"fit-content"}>
                    <SchoolDetailsHeadingContainer color={"#ffffff"}>
                      Details
                    </SchoolDetailsHeadingContainer>
                    <SchoolDetailsSubContainer>
                      <SchoolDetailsText
                        fontSize={"18px"}
                        fontWeight={500}
                        padding={"0 0 0 25px"}
                        fontColor={"#FFFFFF"}
                        textAlign={"left"}
                        width={"150px"}
                      >
                        School Type
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"16px"}
                        fontColor={"#ceefff"}
                        textAlign={"left"}
                        width={"210px"}
                      >
                        {schoolType}
                      </SchoolDetailsText>

                      <SchoolDetailsText
                        fontSize={"18px"}
                        fontWeight={500}
                        padding={"0 0 0 25px"}
                        fontColor={"#FFFFFF"}
                        textAlign={"left"}
                        width={"150px"}
                      >
                        Number of Pupils
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"16px"}
                        fontColor={"#ceefff"}
                        textAlign={"left"}
                        width={"210px"}
                      >
                        {capacity.value}
                      </SchoolDetailsText>

                      <SchoolDetailsText
                        fontSize={"18px"}
                        fontWeight={500}
                        padding={"0 0 0 25px"}
                        fontColor={"#FFFFFF"}
                        textAlign={"left"}
                        width={"150px"}
                      >
                        Establishment Type
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"16px"}
                        fontColor={"#ceefff"}
                        textAlign={"left"}
                        width={"210px"}
                      >
                        {establishmentType}
                      </SchoolDetailsText>

                      <SchoolDetailsText
                        fontSize={"18px"}
                        fontWeight={500}
                        padding={"0 0 0 25px"}
                        fontColor={"#FFFFFF"}
                        textAlign={"left"}
                        width={"150px"}
                      >
                        Academy Trust
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"16px"}
                        fontColor={"#ceefff"}
                        textAlign={"left"}
                        width={"210px"}
                      >
                        {academyTrust}
                      </SchoolDetailsText>

                      <SchoolDetailsText
                        fontSize={"18px"}
                        fontWeight={500}
                        padding={"0 0 0 25px"}
                        fontColor={"#FFFFFF"}
                        textAlign={"left"}
                        width={"150px"}
                      >
                        Website
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"16px"}
                        fontColor={"#ceefff"}
                        textAlign={"left"}
                        width={"210px"}
                      >
                        {websiteURL.value}
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"18px"}
                        fontWeight={500}
                        padding={"0 0 0 25px"}
                        fontColor={"#FFFFFF"}
                        textAlign={"left"}
                        width={"150px"}
                      >
                        Phone Number
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"16px"}
                        fontColor={"#ceefff"}
                        textAlign={"left"}
                        width={"210px"}
                      >
                        {telephone.value}
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"18px"}
                        fontWeight={500}
                        padding={"0 0 0 25px"}
                        fontColor={"#FFFFFF"}
                        textAlign={"left"}
                        width={"150px"}
                      >
                        Email Address
                      </SchoolDetailsText>
                      <SchoolDetailsText
                        fontSize={"16px"}
                        fontColor={"#ceefff"}
                        textAlign={"left"}
                        width={"210px"}
                      >
                        {emailAddress.value}
                      </SchoolDetailsText>
                    </SchoolDetailsSubContainer>
                  </SchoolDetails>
                  <SchoolDetails height={"fit-content"}>
                    <Map
                      latitude={
                        schoolLatitude && schoolLatitude.value
                          ? schoolLatitude.value
                          : 51.509865
                      }
                      longitude={
                        schoolLongitude && schoolLongitude.value
                          ? schoolLongitude.value
                          : -0.118092
                      }
                      zoom={13}
                      width={"100%"}
                      height={355}
                    >
                      <Marker
                        longitude={
                          schoolLongitude && schoolLongitude.value
                            ? schoolLongitude.value
                            : -0.118092
                        }
                        latitude={
                          schoolLatitude && schoolLatitude.value
                            ? schoolLatitude.value
                            : 51.509865
                        }
                      >
                        <SchoolSVG />
                      </Marker>
                      <SchoolAddressMap>
                        <SchoolDetailsHeadingContainer color={"#0caeff"}>
                          Address
                        </SchoolDetailsHeadingContainer>
                        <Address>
                          {addressLine1 && addressLine1 != null && (
                            <SpanText>{addressLine1.value},</SpanText>
                          )}
                          {addressLine2 && addressLine2 != null && (
                            <SpanText>{addressLine2},</SpanText>
                          )}
                          {addressLine3 && addressLine3 != null && (
                            <SpanText>{addressLine3},</SpanText>
                          )}
                          {addressLine4 && addressLine4 != null && (
                            <SpanText>{addressLine4},</SpanText>
                          )}
                          {city && <SpanText>{city.value},</SpanText>}{" "}
                          {postCode && <SpanText>{postCode.value},</SpanText>}
                          {county && <SpanText>{county.value}</SpanText>}
                          {country && <SpanText>{country.value}</SpanText>}
                        </Address>
                      </SchoolAddressMap>
                    </Map>
                  </SchoolDetails>
                </SchoolDetailsContainer>
              </SchoolContainer>
              <TabContainer id="tab-container">
                <TabHolder resetValues={resetValues} id="tab-holder">
                  <Tab label="School Details">
                    <TabWrapper height={"90px"}>
                      {error && <Error message={error} />}
                      {success && <Success message={success} />}
                      <RowContainer>
                        <FieldSubHeading />
                        <Input
                          name="numberOfPupils"
                          placeholder="Number of Pupils"
                          value={capacity.value}
                          type="number"
                          handleChange={handleOnchangeCapacity}
                          error={capacity.error}
                        />
                        <Input
                          name="websiteAddress"
                          placeholder="Website Address"
                          value={websiteURL.value}
                          handleChange={handleOnchangeWebsiteAddress}
                          error={websiteURL.error}
                        />
                      </RowContainer>
                      <ButtonContainer>
                        <PrimaryButton
                          type="button"
                          value="Update"
                          handleClick={() =>
                            editSchool(
                              Constants.messages.UPDATE_SCHOOL_DETAILS,
                              "SCHOOL_DETAILS"
                            )
                          }
                          width={"145px"}
                          height={"48px"}
                          fontSize={"18px"}
                          borderRadius={"6px"}
                        />
                      </ButtonContainer>
                    </TabWrapper>
                  </Tab>

                  <Tab label="Address Details">
                    <TabWrapper height={"100%"}>
                      {error && <Error message={error} />}
                      {success && <Success message={success} />}
                      <RowContainer id="row-container">
                        <FieldSubHeading>Address</FieldSubHeading>
                        <Input
                          name="addressLine1"
                          placeholder="Address Line 1"
                          value={addressLine1.value}
                          error={addressLine1.error}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="addressLine2"
                          placeholder="Address Line 2"
                          value={addressLine2}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="addressLine3"
                          placeholder="Address Line 3"
                          value={addressLine3}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="addressLine4"
                          placeholder="Address Line 4"
                          value={addressLine4}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="city"
                          placeholder="City"
                          value={city.value}
                          error={city.error}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="postCode"
                          placeholder="Post code"
                          value={postCode.value}
                          error={postCode.error}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="county"
                          placeholder="County"
                          value={county.value}
                          error={county.error}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="country"
                          placeholder="Country"
                          value={country.value}
                          error={country.error}
                          handleChange={handleOnchangeAddress}
                        />
                        <FieldSubHeading>Contact</FieldSubHeading>
                        <Input
                          name="telephone"
                          placeholder="Telephone"
                          value={telephone.value}
                          error={telephone.error}
                          handleChange={handleOnchangeTelephone}
                        />
                        <Input
                          name="emailAddress"
                          placeholder="Email Address"
                          value={emailAddress.value}
                          error={emailAddress.error}
                          type="email"
                          handleChange={handleOnchangeEmail}
                        />
                        <FieldSubHeading>Location Co-ordinates</FieldSubHeading>
                        <Input
                          name="latitude"
                          placeholder="Latitude"
                          value={schoolLatitude.value}
                          error={schoolLatitude.error}
                          handleChange={handleOnchangeAddress}
                        />
                        <Input
                          name="longitude"
                          placeholder="Longitude"
                          value={schoolLongitude.value}
                          error={schoolLongitude.error}
                          handleChange={handleOnchangeAddress}
                        />
                      </RowContainer>
                      <ButtonContainer>
                        <PrimaryButton
                          type="button"
                          value="Update"
                          handleClick={() =>
                            editSchool(
                              Constants.messages.UPDATE_SCHOOL_ADDRESS,
                              "ADDRESS"
                            )
                          }
                          width={"145px"}
                          height={"48px"}
                          fontSize={"18px"}
                          borderRadius={"6px"}
                        />
                      </ButtonContainer>
                    </TabWrapper>
                  </Tab>
                  <Tab label="Settings">
                    <TabWrapper height={"fit-content"}>
                      {error && <Error message={error} />}
                      {success && <Success message={success} />}
                      <RowContainer id="row-container">
                        <FieldSubHeading>Data Verification</FieldSubHeading>
                        <SettingsFields>
                          <SettingsLabel id="DataVerification">
                            Participating Profile Data
                          </SettingsLabel>
                          <SettingsFormElement>
                            <SpanText>
                              {profileApprovalEnabled ? "Yes" : "No"}
                            </SpanText>
                          </SettingsFormElement>
                        </SettingsFields>

                        <FieldSubHeading>Evening Dismissal</FieldSubHeading>
                        <SettingsFields>
                          <SettingsLabel id="OTPBasedDismissal">
                            OTP Based Dismissal
                          </SettingsLabel>
                          <SettingsFormElement>
                            <Switch
                              checked={otpBasedDismissal}
                              onChange={handleOTPBasedDismissal}
                              onColor="#86d3ff"
                              onHandleColor="#0CAEFF"
                              handleDiameter={20}
                              uncheckedIcon={false}
                              checkedIcon={false}
                              boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                              activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                              height={18}
                              width={35}
                              className="react-switch"
                              id="material-switch"
                            />
                          </SettingsFormElement>
                        </SettingsFields>
                        <SettingsFields>
                          <SettingsLabel id="SchoolDismissalTime">
                            School Dismissal Time
                          </SettingsLabel>
                          <SettingsFormElement>
                            <TimePicker
                              defaultValue={dismissalTime}
                              beginLimit="11:55AM"
                              endLimit="6:00PM"
                              step={5}
                              onChange={onDissimissalTimeChange}
                            />
                          </SettingsFormElement>
                        </SettingsFields>
                        <SettingsFields>
                          <SettingsLabel id="ScheduleCutoffTime">
                            Evening Schedule Cut-Off Time
                          </SettingsLabel>
                          <SettingsFormElement>
                            <TimePicker
                              defaultValue={eveningScheduleCutOffRange}
                              beginLimit="11:55AM"
                              endLimit="6:00PM"
                              step={5}
                              disabled={!otpBasedDismissal}
                              onChange={onLastScheduleTimeChange}
                            />
                          </SettingsFormElement>
                        </SettingsFields>
                        <FieldSubHeading>Logo Settings</FieldSubHeading>
                        <Input
                          name="logourl"
                          placeholder="Logo Location"
                          value={logoUrl}
                          handleChange={handleOnchangeLogoURL}
                        />
                        <ColorPickerContainer>
                          <SchoolLogoBackgroundColorLabel id="back-ground">
                            Choose Background Colour
                          </SchoolLogoBackgroundColorLabel>
                          <ColorPicker
                            id="sketch-picker"
                            value={
                              schoolLogoBackgroundColor
                                ? schoolLogoBackgroundColor
                                : "#000"
                            }
                            onChange={handleOnchangeBackgroundColor}
                          />
                        </ColorPickerContainer>
                      </RowContainer>
                      <ButtonContainer>
                        <PrimaryButton
                          type="button"
                          value="Update"
                          handleClick={() =>
                            editSchool(
                              otpBasedDismissal
                                ? Constants.messages
                                    .UPDATE_SETTINGS_OTP_SWITCHED_ON
                                : Constants.messages
                                    .UPDATE_SETTINGS_OTP_SWITCHED_OFF,
                              "SETTINGS"
                            )
                          }
                          width={"145px"}
                          height={"48px"}
                          fontSize={"18px"}
                          borderRadius={"6px"}
                        />
                      </ButtonContainer>
                    </TabWrapper>
                  </Tab>
                </TabHolder>
              </TabContainer>
            </Fragment>
          )}
        </ContentContainer>
      </Container>
    </RecoilRoot>
  );
};
