import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";

const StyledSuccessMessage = styled.div`
  color: #07bc00;
  font-size: 10px;
  text-align: center;
`;

export const Success = ({ message }) => {
  return <StyledSuccessMessage>{message}</StyledSuccessMessage>;
};

Success.propTypes = {
  message: PropTypes.string
};
