import React, { useState, useEffect } from "react";
import { SideBar } from "../hoc/Navigation";
import styled from "styled-components";
import { Switch } from "react-router-dom";
import { Verification } from "../hoc/FamilyProfile";
import { DashBoard } from "../hoc/Dashboard/dashboard";
import { Signout } from "../hoc/Signout/handlesignout";
import { SchoolOverview } from "../hoc/SchoolProfile/Details/Overview";
import { TripDetails } from "../hoc/TripDetails/TripDetails";
import { HeaderLogo } from "../components";
import { ReactComponent as Skewllogo } from "../static/skewl-logo.svg";
import { ReactComponent as BellIcon } from "../static/bell.svg";
import { ProtectedRoute } from "../hoc/Routes/protected-route";
import skewlAuth from "../services/skewl-auth";
import PropTypes from "prop-types";

const FullPage = styled.div`
  display: flex;
  width: 100%;
  background: #fafafb;
  height: 100vh;
`;

const MainArea = styled.div`
  display: flex;
  width: 100%;
`;
const SideNavContainer = styled.div`
  display: flex;
  max-height: 100%;
  flex-direction: column;
  z-index: 1;
  background: #ffffff;
  width: 20%;
`;
const PoweredByText = styled.span`
  height: 25px;
  width: 100px;
  color: #959393;
  font-family: Open Sans;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
`;
const PoweredBy = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  padding-left: 57.41px;
  padding-right: 56.59px;
  align-items: center;
`;
const HeaderText = styled.div`
  height: 37px;
  width: 35%;
  color: #3f3b3b;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: bold;
  margin-top: 19px;
  letter-spacing: -0.28px;
  line-height: 37px;
  margin-left: 21%;
  position: absolute;
`;
const BellContainer = styled.div`
  margin: 19px 0 0 95%;
`;
const MainHeader = styled.div`
  height: 81px;
  width: 100%;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 0 1px 0 rgba(40, 41, 61, 0.04),
    0 2px 4px 0 rgba(96, 97, 112, 0.16);
  display: flex;
  align-items: center;
  position: absolute;
`;
export const Main = ({ match }) => {
  document.title = "Skewl | Main";
  const [title, setTitle] = useState("Dashboard");
  const [isUserSuperAdmin, setIsSuperAdmin] = useState(false);

  useEffect(() => {
    async function isUserSuperAdmin() {
      setIsSuperAdmin(await skewlAuth.doesUserSuperAdmin());
    }
    isUserSuperAdmin();
  }, []);

  return (
    <FullPage id="main-container">
      <MainArea id="skewl-main-area">
        <MainHeader id="main-header">
          <HeaderText id="header-text">{title}</HeaderText>
          <BellContainer>
            <BellIcon />
          </BellContainer>
        </MainHeader>
        <SideNavContainer id="side-navigation">
          <HeaderLogo />
          <SideBar setTitle={setTitle} isUserSuperAdmin={isUserSuperAdmin} />
          <PoweredBy>
            <PoweredByText>Powered by</PoweredByText>
            <Skewllogo />
          </PoweredBy>
        </SideNavContainer>
        <Switch>
          <ProtectedRoute
            exact
            path={`${match.url}/dashboard`}
            component={DashBoard}
          />
          <ProtectedRoute
            exact
            path={`${match.url}/verification`}
            component={Verification}
            isSuperAdmin={isUserSuperAdmin}
          />
          <ProtectedRoute
            exact
            path={`${match.url}/school`}
            component={SchoolOverview}
          />
          <ProtectedRoute
            exact
            path={`${match.url}/trip`}
            component={TripDetails}
          />
          <ProtectedRoute
            exact
            path={`${match.url}/onboard`}
            component={TripDetails}
          />
          <ProtectedRoute
            exact
            path={`${match.url}/signout`}
            component={Signout}
          />
        </Switch>
      </MainArea>
    </FullPage>
  );
};
Main.propTypes = {
  match: PropTypes.object
};
