import styled from "styled-components";
import React from "react";
import PropTypes from "prop-types";
import { motion, AnimatePresence } from "framer-motion";

const BackDropWrapper = styled(motion.div)`
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const backDrop = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 }
};

export const BackDrop = (props) => {
  return (
    <AnimatePresence
      exitBeforeEnter
      onExitComplete={() => props.setShowBackdrop(false)}
    >
      {props.showBackdrop && (
        <BackDropWrapper variants={backDrop} intial="hidden" animate="visible">
          {props.children}
        </BackDropWrapper>
      )}
    </AnimatePresence>
  );
};

BackDrop.propTypes = {
  showBackdrop: PropTypes.bool,
  children: PropTypes.node,
  setShowBackdrop: PropTypes.func
};
