import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  activeIndexAtom,
  labelArrayAtom,
  activeLabelSelector
} from "./TabState";
import styled from "styled-components";
import PropTypes from "prop-types";
import { motion } from "framer-motion";
import { useMeasure } from "./UseMeasure";

const TabContainer = styled.div`
  overflow: hidden;
  box-shadow: none;
`;
const TabList = styled.div`
  display: block;
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin-top: 35px;
`;

const TabItem = styled(motion.div)`
  width: 136px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(p) => (p.isActive ? "#FFFFFF" : "#3F3B3B")};
`;

const TabContent = styled.div`
  width: 100%;
  height: 100%;
  visibility: ${(p) => p.visibility};
  aria-hidden: ${(p) => p.ariaHidden};
`;

const Slider = styled(motion.div)`
  width: 136px;
  height: 32px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  position: absolute;
  background: linear-gradient(90deg, #1cd7ff 0%, #0caeff 100%);
`;

const TabContentContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 300%;
  height: 100%;
  position: absolute;
`;

const TabAnimationContainer = styled(motion.div)`
  flex-direction: row;
  direction: ltr;
  will-change: transform;
  min-height: 0;
  flex: 1;
  display: flex;
  height: 100%;
`;

export const TabHolder = (props) => {
  const [activeIndex, setActiveIndex] = useRecoilState(activeIndexAtom);
  const setLabelArray = useSetRecoilState(labelArrayAtom);
  const { activeIndex: initialActiveIndex, children } = props;
  const childrenArray = useMemo(
    () => (Array.isArray(children) ? children : [children]),
    [children]
  );

  const childRefs = useRef(new Map());
  const tabListRef = useRef();
  const [slider, setSlider] = useState({ left: 0, right: 0 });
  const { bounds, ref } = useMeasure();

  /** always find previous tab value to reset the values when move between the tabs */
  const previosuActiveLabel = useRecoilValue(activeLabelSelector);

  useEffect(() => {
    const tabLabels = childrenArray.map((tab) => tab.props.label);
    setLabelArray(tabLabels);
    if (initialActiveIndex > 0 && initialActiveIndex < tabLabels.length) {
      setActiveIndex(initialActiveIndex);
    }
    const target = childRefs.current.get(activeIndex);
    const container = tabListRef.current;
    if (target) {
      const cRect = container.getBoundingClientRect();
      // when container is `display: none`, width === 0.
      // ignore this case
      if (cRect.width === 0) {
        return;
      }

      const tRect = target.getBoundingClientRect();
      const left = tRect.left - cRect.left;
      const right = cRect.right - tRect.right;

      setSlider({
        hasValue: true,
        left: left,
        right: right
      });
    }
  }, [
    childrenArray,
    initialActiveIndex,
    setActiveIndex,
    setLabelArray,
    activeIndex,
    bounds
  ]);

  return (
    <>
      <TabContainer ref={ref}>
        <TabList ref={tabListRef}>
          {childrenArray.map((tab, index) => (
            <TabItem
              id="name-container"
              key={tab.props.label}
              isActive={index === activeIndex}
              ref={(el) => childRefs.current.set(index, el)}
              onClick={() => {
                props.resetValues ? props.resetValues(previosuActiveLabel) : "";
                setActiveIndex(index);
              }}
            >
              {tab}
            </TabItem>
          ))}
          {slider.hasValue && (
            <Slider
              id="tab-slider"
              positionTransition={{
                bounceDamping: 3
              }}
              initial={false}
              animate={{ left: slider.left, right: slider.right }}
              transition={{ duration: 0.5 }}
            />
          )}
        </TabList>
      </TabContainer>
      <TabContentContainer id="tab-content-container">
        <TabAnimationContainer
          id="tab-animation-container"
          transition={{
            tension: 190,
            friction: 70,
            mass: 0.4,
            duration: 0.5
          }}
          initial={false}
          animate={{ x: (activeIndex * -100) / childrenArray.length + "%" }}
        >
          {childrenArray.map((tab, index) => (
            <TabContent
              key={index}
              ariaHidden={activeIndex !== index}
              visibility={activeIndex !== index ? "hidden" : "visible"}
              tabIndex={activeIndex === index ? 0 : -1}
              id={tab.props.label}
            >
              {tab.props.children}
            </TabContent>
          ))}
        </TabAnimationContainer>
      </TabContentContainer>
    </>
  );
};

TabHolder.propTypes = {
  activeIndex: PropTypes.string,
  resetValues: PropTypes.func,
  children: PropTypes.node
};
