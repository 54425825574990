const BUCKET_NAME = process.env.REACT_APP_S3_BUCKET_NAME;
const config = {
  s3: {
    REGION: "eu-west-2",
    BUCKET: BUCKET_NAME,
  },
  cognito: {
    REGION: "eu-west-2",
    USER_POOL_ID: "eu-west-2_yxjmfOoud",
    APP_CLIENT_ID: "1q3t52c81apj116me49r09rf1b",
    IDENTITY_POOL_ID: "eu-west-2:890c286f-a03f-47f6-941b-2a342a8a3b63",
  },
};

export default config;
