import React, { useState } from "react";
import PropTypes from "prop-types";
import { InputWithSearch } from "../../components";

export const SearchUrn = ({ value, handleChange, handleOnClick }) => {
  const [error, setError] = useState("");
  const [schoolUrn, setSchoolUrn] = useState(value);

  const handleValidation = (urn) => {
    let err;
    if (!urn) {
      err = "This information is mandatory";
    } else if (!urn.match("^\\d{6}$")) {
      err = "School Urn should be a 6 digit number";
    } else {
      err = "";
    }
    return err;
  };

  const handleOnInputWithValidation = (event) => {
    const e = handleValidation(event.target.value);
    setSchoolUrn(event.target.value);
    setError(e);
    handleChange({ value: event.target.value, error: e });
  };

  const handleOnClickWithValidation = () => {
    handleValidation(schoolUrn);
    if(!error) handleOnClick();
  };

  return (
    <InputWithSearch
      name="schoolUrn"
      placeholder="School URN"
      value={schoolUrn}
      handleOnInput={handleOnInputWithValidation}
      handleOnClick={handleOnClickWithValidation}
      error={error}
    />
  );
};

SearchUrn.propTypes = {
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleOnClick: PropTypes.func,
};
