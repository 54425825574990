import axios from "axios";
import SkewlAuth from "./skewl-auth";
import { Constants } from "../utils/constants";

const SKEWL_HOST = process.env.REACT_APP_API_URL;

export const getSchoolDetails = async (urn) => {
  let schoolDetails;
  try {
    let apiToken = await SkewlAuth.getToken();
    let session_id = sessionStorage.getItem("session_id");
    schoolDetails = {
      schoolName: "",
      schoolAddress: "",
      error: "",
    };
    let config = {
      method: "get",
      url: `${SKEWL_HOST}/v1/school/search/${encodeURIComponent(session_id)}`,
      headers: {
        Authorization: apiToken,
        "Content-Type": "application/json",
      },
    };
    if (urn) {
      config.url = config.url + "/" + urn;
    }

    const res = await axios(config);
    if (res.status === Constants.http_status_codes.SUCCESS) {
      updateSession(res.data.result);
      return {
        schoolName: res.data.result["establishment_name"],
        academyTrust: res.data.result["academy_trust"],
        websiteURL: res.data.result["website_url"],
        capacity: res.data.result["capacity"],
        schoolType: res.data.result["school_type"],
        establishmentType: res.data.result["establishment_type"],
        telephone: res.data.result["telephone"],
        emailAddress: res.data.result["email_address"],
        locationCoordinates: res.data.result["location_coordinates"],
        schoolAddress: res.data.result["address_details"],
        schoolSettings: res.data.result["settings"],
        error: "",
      };
    } else if (res.status === Constants.http_status_codes.NO_CONTENT) {
      updateSession(res.data.result);
      return { ...schoolDetails, error: "No school is found with the urn" };
    } else {
      throw new Error("unknown error");
    }
  } catch (error) {
    console.log("Error occurred while getting school details for the urn: ");
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.SCHOOL_SEARCH_100;
    }
    return {
      ...schoolDetails,
      error: errorCode,
    };
  }
};

export const updateSchoolAdminDetails = async (
  urn,
  firstName,
  lastName,
  email,
  designation,
  userId
) => {
  let session_id = sessionStorage.getItem("session_id");
  const data = {
    urn,
    first_name: firstName,
    last_name: lastName,
    email,
    designation,
    audit: { user_id: userId },
    session_id: session_id,
  };
  let apiToken = await SkewlAuth.getToken();

  const config = {
    method: "post",
    url: `${SKEWL_HOST}/v1/user/admin/onboard`,
    headers: {
      Authorization: apiToken,
      "Content-Type": "application/json",
    },
    data: data,
  };

  let res;
  try {
    res = await axios(config);
    if (res.status === Constants.http_status_codes.RESOURCE_CREATED) {
      updateSession(res.data.result);
      return {
        error: "",
      };
    } else if (res.status === Constants.http_status_codes.CONFLICTS) {
      updateSession(res.data.result);
      return {
        error: Constants.error.SCHOOL_ADMIN_ONBOARD_102,
      };
    } else if (res.status === Constants.http_status_codes.PRECONDITION_FAILED) {
      updateSession(res.data.result);
      return {
        error: Constants.error.SCHOOL_ADMIN_ONBOARD_101,
      };
    } else {
      throw new Error("Server Error");
    }
  } catch (error) {
    // console.log("Error while saving school details", error, res);
    console.log(error);
    let errorCode;
    if (
      (error.code && error.code === "NotAuthorizedException") ||
      error === "No current user"
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else if (
      error.response.status &&
      error.response.status === Constants.http_status_codes.UNAUTHROISED
    ) {
      errorCode = Constants.http_status_codes.UNAUTHROISED;
    } else {
      errorCode = Constants.error.SCHOOL_ADMIN_ONBOARD_100;
    }
    return {
      error: errorCode,
    };
  }
};

const updateSession = (result) => {
  if (result && result.session_id) {
    sessionStorage.setItem("session_id", result.session_id);
  }
};
