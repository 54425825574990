import React from "react";
import { ReactComponent as Profile } from "../../static/profile.svg";
import PropTypes from "prop-types";
import { InputWithIcon } from "../../components";

export const Username = ({ value, handleOnInput }) => {
  const handleUsernameWithValidation = (event) => {
    handleOnInput(event.target.value);
  };

  return (
    <InputWithIcon
      name="Username"
      placeholder="Username"
      type="text"
      Icon={Profile}
      value={value}
      handleChange={handleUsernameWithValidation}
    />
  );
};

Username.propTypes = {
  value: PropTypes.string,
  handleOnInput: PropTypes.func
};
