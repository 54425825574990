import React from "react";
import { ReactComponent as PasscodeIcon } from "../../static/passcode.svg";
import PropTypes from "prop-types";
import { InputWithIcon } from "../../components";

export const Passcode = ({ value, handleOnInput, error }) => {
  const handlePasscodeValidation = (event) => {
    if (!event.target.value) {
      console.log("no username provided");
    }
    handleOnInput(event.target.value);
  };

  return (
    <InputWithIcon
      name="Passcode"
      placeholder="Passcode"
      type="text"
      Icon={PasscodeIcon}
      value={value}
      handleChange={handlePasscodeValidation}
      error={error}
    />
  );
};

Passcode.propTypes = {
  value: PropTypes.string,
  handleOnInput: PropTypes.func,
  error: PropTypes.string,
};
